import React, { useState } from 'react'
import { options } from '../utils/faqOptions'

export default function Faq() {

    const [tabCount, setTabCount] = useState(1)

    return (
        <div>


            <section className="inner-banner">

                <div className="container">

                    <div className="contents">

                        <h1>Faq</h1>

                        <p>Please feel free to reach out to our sales team for any further questions or clarifications on our pricing and plans.<br/>We are here to assist you in finding the best-suited plan for your construction management needs.</p>

                    </div>

                </div>

            </section>


            <section className="faq-outer grey-bg padding-lg" id="faq-sec">

                <div className="container">

                    <div className="row">

                        <div className="col-md-3 faq-left">
                            <ul>
                                <li className={tabCount === 1 ? "active" : ""}>
                                    <div className='each-card' style={{padding:"20px"}} onClick={() => setTabCount(1)}>
                                        <div className="icon"><span className="icon-general"></span></div>
                                        <div className="cnt-block" >
                                            <h3>General Enquiries</h3>
                                            <p>Product Related</p>
                                        </div>
                                    </div> </li>
                                <li className={tabCount === 2 ? "active" : ""}>
                                    <div className='each-card' style={{padding:"20px"}} onClick={() => setTabCount(2)}>
                                        <div className="icon"><span className="icon-features"></span></div>
                                        <div className="cnt-block">
                                            <h3>Features</h3>
                                            <p>Contract Management</p>
                                        </div>
                                    </div> </li>
                                <li className={tabCount === 3 ? "active" : ""}>
                                    <div className='each-card' style={{padding:"20px"}} onClick={() => setTabCount(3)}>
                                        <div className="icon"><span className="icon-privacy"></span></div>
                                        <div className="cnt-block">
                                            <h3>Permission & Privacy</h3>
                                            <p>Security</p>
                                        </div>
                                    </div> </li>
                                <li className={tabCount === 4 ? "active" : ""}>
                                    <div className='each-card' style={{padding:"20px"}} onClick={() => setTabCount(4)}>
                                        <div className="icon"><span className="icon-pricing"></span></div>
                                        <div className="cnt-block">
                                            <h3>Pricing & Plans</h3>
                                            <p>Benefits</p>
                                        </div>
                                    </div> </li>
                            </ul>
                        </div>
                        <div className="col-md-9 faq-right">
                            <div id="accordion" role="tablist">
                                {options.map((each: any, index: number) => {
                                    if (each.id === tabCount) {
                                        return (
                                            <div className="card">
                                                <div className="card-header" role="tab" id="headingOne">
                                                    <h5 className="mb-0"> <a data-toggle="collapse" href={`#test${index}`} aria-expanded="true" aria-controls={`test${index}`}>{each.questions}</a> </h5>
                                                </div>
                                                <div id={`test${index}`} className="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p>{each.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>

                    </div>

                </div>

            </section>

            {/* <section className="signup-outer gradient-bg padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10">

                            <ul className="clearfix">

                                <li> <span className="icon-men"></span>

                                    <h4>Signup for an <span>Account</span></h4>

                                </li>

                                <li> <span className="icon-chat"></span>

                                    <h4>Discuss with <span>our team</span></h4>

                                </li>

                                <li> <span className="icon-lap"></span>

                                    <h4>Receive a <span>good support</span></h4>

                                </li>

                            </ul>

                            <div className="signup-form">

                                <form action="#" method="get">

                                    <div className="email">

                                        <input name="email" type="text" placeholder="email" />

                                    </div>

                                    <div className="password">

                                        <input name="password" type="password" placeholder="password" />

                                    </div>

                                    <button className="signup-btn">Sign up Now</button>

                                </form>

                            </div>

                        </div>

                    </div>

                </div>

            </section> */}
        </div>
    )
}
