import * as React from "react";
import { Link } from "react-router-dom";
import "./login.scss";
import { api_service } from "../../api/api_service";
import logo from "../../assets/images/cctLogo.svg"
import withRouter from "../../components/common/withRoter";

interface LoginPayload {
  email: any;
  password: any;
}

interface LoginInfoState {
  email: any;
  password: any;
  db_data: Array<any>;
  errMsg: any;
}

interface ILoginProps {
  history?: any;
  match?: any;
  //   Dispatch: Dispatch<Action>;
  isLoggedIn?: boolean;
  isCollobra?: boolean;
}

// interface ILoginDetails {
//   login_code?: number;
//   phone?: number;
// }

class Login extends React.Component<ILoginProps, LoginInfoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
      db_data: [],
      errMsg: "",
    };
  }

  payload: LoginPayload = {
    email: null,
    password: null,
  };

  jwtDecode(token: string) {
    token = token.split(".")[1];
    let userDetails: any = JSON.parse(window.atob(token));
    return userDetails;
  }

  componentDidMount() {
    // var $element = $("#loader");
    // $element.fadeOut(1000);
    api_service
      .get({
        endPoint: api_service.api_urls.USERS,
        domain: "http://44.215.80.246:4000",
      })
      ?.then((res: any) => {
        if (res.data.status === 200) {
          this.setState({ db_data: res.data.data });
        }
      })
      .catch((err: any) => {
        console.log(err.message);
      });
  }

  saveHandler(e: any) {
    e.preventDefault();
    if (
      this.state.db_data.some(
        (element: any) =>
          element.email === this.state.email &&
          element.password === this.state.password
      )
    ) {
      let name = this.state.db_data.filter(
        (element: any) => element.email === this.state.email
      );
      console.log("success");
      this.props.history("development", { replace: true });
      sessionStorage.setItem("user", JSON.stringify(name[0].name));
    } else {
      alert("invalid username or password");
      console.log("check");
    }
  }

  changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  errors: any = {};

  formValidations(key: string) {
    if (key === "all") {
      this.errors = {};
    }
    if (key === "all" || key === "email") {
      console.log(this.state.email);
      if (!this.state.email) {
        this.errors.email = "Please enter the email.";
      } else {
        this.payload.email = this.state.email.trim(); // 919133532445
        delete this.errors.email;
      }
    }
    if (key === "all" || key === "password") {
      console.log(this.state.password);
      if (!this.state.password) {
        this.errors.password = "Please enter the password.";
      } else {
        this.payload.password = this.state.password.trim(); // 123456
        delete this.errors.password;
      }
    }
    this.setState({});
    return Object.keys(this.errors).length === 0;
  }

  submit(e: any) {
    e.preventDefault();
    if (this.formValidations("all")) {
      api_service
        .post({
          endPoint: api_service.api_urls.ADMIN_LOGIN,
          domain: "http://44.215.80.246:4001",
          // domain: api_service.BaseDomain.Node.Users,
          payLoad: { attributes: this.payload },
        })
        .then((res: any) => {
          if (res.data && res.data.status === 200) {
            res.data = res.data.data;
            try {
              // this.props.Dispatch(UserActions.updateAuthKey_new({
              //   token: res.data.token,
              //   timestamp: res.data.token_expiry,
              //   name: res.data.name,
              //   email: res.data.email
              // }));
              let decodeRefreshToken = this.jwtDecode(res.data.token);
              console.log("164", decodeRefreshToken);
              // this.props.Dispatch(UserActions.updateUserCredentials(decodeRefreshToken));
              // appConfig.AuthInfo.Token = res.data.token;
              sessionStorage.setItem("token", JSON.stringify(res.data.token));
              sessionStorage.setItem(
                "user_id",
                JSON.stringify(decodeRefreshToken.userId)
              );
              // console.log("172",JSON.stringify(AppConfig.AuthInfo.Token))
            } catch (e) {
              console.log(e);
            }
            //appConfig.AuthInfo.Token = res.data.token || '';
            // this.storeLoginRoleFeatures(res);
            // this.props.router.push('/dashboard');
            sessionStorage.setItem("token", JSON.stringify(res.data.token));

            this.props.history("/usermanagment", { replace: true });
          } else {
            if (
              res.data === "WRONG-CREDENTIALS" ||
              res.data === "USER-STATUS-INACTIVE"
            ) {
            }
            console.log("WRONG-CREDENTIALS");
            // this.state.errMsg="WRONG-CREDENTIALS";
            this.setState({
              errMsg: "WRONG-CREDENTIALS",
            });
          }
          // this.setState({});
        })
        .catch((err: any) => {
          // console.log("WRONG-CREDENTIALS");
          this.setState({
            errMsg: "WRONG-CREDENTIALS",
          });
          // console.log(err)
          // utility.alert({ message: err.message });
          //Utility.alert(err.message);
        });
    }
  }

  render() {
    return (
      <div>
        <div className="d-flex login">
          <div className="d-flex justify-content-between flex-column details_section">
            <div>
              <div className="uma_logo">
                <img
                src={logo}
                  // src={require("../../assets/images/cctLogo.png")}
                  alt="uma logo"
                />
              </div>
              <div className="header">
                <h4 className="page_header">Sign in</h4>
                <br />
                {
                  <span className="login-error_msg">
                    {this.state.errMsg ? "Invalid Login Credentials" : ""}
                  </span>
                }
                <p>Enter your email address and password to <br/> access account.</p>
              </div>
              <form
                className="d-flex flex-column id-passwoord"
                onSubmit={(e) => {
                  this.saveHandler(e);
                }}
              >
                <div className="input_style">
                  <label className="">Enter Your Email</label>
                  <input
                    name="email"
                    type="email"
                    className=""
                    placeholder="Your email address"
                    onBlur={this.formValidations.bind(this, "email")}
                    onChange={this.changeHandler}
                    required
                  ></input>
                </div>
                {/* <span >{this.errors.email ? this.errors.email : ''}</span> */}

                <div className="input_style">
                  <label className="">Enter Your Password</label>
                  <input
                    name="password"
                    type="password"
                    className=""
                    placeholder="Password"
                    onBlur={this.formValidations.bind(this, "password")}
                    onChange={this.changeHandler}
                    required
                  ></input>
                </div>
                {/* <span>{this.errors.password ? this.errors.password : ''}</span> */}

                <div className="confirmation_checkbox">
                  <div className="confirmation_checkbox_element">
                    <input
                      required
                      className="input_confirmation_checkbox"
                      type="checkbox"
                    ></input>
                    <span>Keep me logged in</span>
                  </div>
                  <div>
                    <span>
                      <Link to="reset/mail">oh no, I forgot my password</Link>
                    </span>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={this.submit.bind(this)}
                  className="signin_btn"
                >
                  SIGN IN
                </button>
              </form>
            </div>
            <div>
              <p className="pageNavigation_text">
                Don't have an account account ?{" "}
                <Link className="pageNavigation_link" to="signup">
                  Sign up
                </Link>
              </p>
            </div>
          </div>

          <div className="display_picture">
            <img
              src={require("../../assets/images/login-bg.png")}
              alt="pic"
            ></img>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);

// function mapStoreToProps(store: LoginInfoState) {
//   return {
//       // isLoggedIn: store.App.userDetails.Token ? true : false,
//       // isCollobra: store.App.userDetails.role === USERS.COLLOBRA ? true : false
//   };
// }

// const mapDispatchToProps = dispatch => ({
//   dispatch
// })

// const mapDispatchToProps(dispatch: Dispatch<any>): { Dispatch: Dispatch<any> } {
//   return {
//       Dispatch: dispatch
//   };
// }
// export default withRouter(connect()(Login));
