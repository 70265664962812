import React, { Component, RefObject } from 'react'
import ContactUs from '../../components/common/ContactUs'
import withRouter from '../../components/common/withRoter';
import { api_service } from '../../api/api_service';
import UserFormModal from './UserFormModal';
import RoiCalculator from './RoiCalculator';
import StrikeThroughAmount from './StrikeThroughAmount';

interface IProps {
    history?: any;
    match?: any;
    isLoggedIn?: boolean;
    isCollobra?: boolean;
}

declare global {
    interface Window {
        api?: any;
        Razorpay: any;
    }
}

interface iState {
    isAddButtonClicked: boolean;
    id: any;
    planData: Array<any>;
    name: string;
    account: string;
    gst: string;
    plan_id: number;
    features: any;
    plansData: any
}

class Pricing extends Component<IProps, iState> {

    // plansData: any = [];
    // features: any = [];
    private formRef: RefObject<HTMLFormElement>;


    constructor(ILoginProps: any) {
        super(ILoginProps);
        this.formRef = React.createRef();
        this.state = {
            isAddButtonClicked: false,
            id: "",
            planData: [{ features: [] }],
            name: "",
            account: "",
            gst: "",
            plan_id: 0,
            features: [],
            plansData: []
        }
        this.openPayModal = this.openPayModal.bind(this);
    }
    closeModal() {
        this.setState((p) => ({ isAddButtonClicked: false }));
    }

    async loadScript(src: any) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async openPayModal(plan_id: any) {
        // var amount = amt * 100; //Razorpay consider the amount in paise
        var email = prompt('Please enter your email');

        const res = await this.loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
        );

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        var options = {
            "key": "rzp_test_Okjl451cQAmZCs",
            "amount": 0, // 2000 paise = INR 20, amount in paisa
            "name": "Merchant Name",
            'order_id': "",
            "handler": async function (response: any) {
                var values: any = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: plan_id,
                }

                try {
                    await api_service
                        .post({
                            endPoint: "/v1/upgrade/payment",
                            domain: "http://44.215.80.246:4007",
                            payLoad: values
                        })
                        ?.then((res) => {


                            if (res.data.status === 200) {
                                alert("Success")
                                // //console.log("----------")
                                // //console.log(JSON.stringify(this.state.data))
                            }
                        })
                        .catch((err) => {
                            //console.log(err);
                        });
                } catch (err) {
                    console.log(err);
                }

                //   api_service.post('http://44.215.80.246:5000/upgrade/payment',values)
                //   .then(res=>{alert("Success")})
                //   .catch(e=>console.log(e))


            },
            "theme": {
                "color": "#528ff0"
            }
        };


        try {

            let payload: any = {
                attributes: {
                    plan_id: plan_id,
                    email: email
                },
            };


            await api_service
                .post({
                    endPoint: "/v1/generateOrderTokenRazorpay",
                    domain: "http://44.215.80.246:4007",
                    payLoad: payload
                })
                ?.then((res) => {

                    console.log(res.data.data)
                    if (res.data.status === 200) {
                        alert("Success")

                        options.order_id = res.data.data.id;
                        options.amount = res.data.data.amount;
                        console.log(options)
                        var rzp1 = new window.Razorpay(options);

                        rzp1.open();


                        // //console.log("----------")
                        // //console.log(JSON.stringify(this.state.data))

                    }
                })
                .catch((err) => {
                    //console.log(err);
                });
        } catch (err) {
            console.log(err);
        }


        // axios.post('http://44.215.80.246:5000/upgrade/order',{amount:amount})
        // .then(res=>{
        //     options.order_id = res.data.id;
        //     options.amount = res.data.amount;
        //     console.log(options)
        //     var rzp1 = new window.Razorpay(options);
        //     rzp1.open();
        // })
        // .catch(e=>console.log(e))

    };

    extractFeatures = (plans: any) => {
        const features: any = {};
        plans.forEach((plan: any) => {
            plan.PlanFeatures.forEach((planFeature: any) => {
                planFeature.Features.forEach((feature: any) => {
                    if (!features[feature.name]) {
                        features[feature.name] = { Basic: null, Premium: null, Enterprise: null };
                    }
                    features[feature.name][plan.name] = feature;
                });
            });
        });
        return features;
    };

    componentDidMount() {
        this.fetchData();
        this.fetchData1();
    }

    fetchData = async () => {
        // this.loader()
        try {
            await api_service
                .get({
                    endPoint: api_service.api_urls.FETCH_PLANS + "?role_id=1",
                    domain: "http://44.215.80.246:4007"
                })
                ?.then((res) => {
                    if (res.data.status === 200) {
                        // console.log(JSON.stringify(res.data.data));

                        this.setState({ planData: res.data.data });

                        // //console.log("----------")
                        // //console.log(JSON.stringify(this.state.data))

                    }
                })
                .catch((err) => {
                    //console.log(err);
                })
            // .finally(()=>{
            //     var $element = $("#loader");
            //     $element.fadeOut(1000);
            // })
        } catch (err) {
            //console.log(err);
        }
    };

    fetchData1 = async () => {
        this.loader()
        try {
            await api_service
                .get({
                    endPoint: api_service.api_urls.FETCH_PLANS_1,
                    domain: "http://44.215.80.246:4001"
                })
                ?.then((res) => {
                    if (res.data.status === 200) {
                        // this.plansData = res.data.data;
                        this.setState({ plansData: res.data.data })
                        const features: any = {};
                        res.data.data.forEach((plan: any) => {
                            plan.PlanFeatures.forEach((planFeature: any) => {
                                planFeature.Features.forEach((feature: any) => {
                                    if (!features[feature.name]) {
                                        features[feature.name] = { Basic: null, Premium: null, Enterprise: null };
                                    }
                                    features[feature.name][plan.name] = feature;
                                });
                            });
                        });
                        this.setState({ features: features })
                        // return features;
                        // this.features = this.extractFeatures(res.data.data);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                })
                .finally(() => {
                    var $element = $("#loader");
                    $element.fadeOut(1000);
                })
        } catch (err) {
            //console.log(err);
        }
    };
    onClick = (e: any) => {

        console.log(e)
        return e;

    }

    async handleSubmit(e: any) {
        e.preventDefault();
        // alert(this.props.id)
        let plan_id = this.state.id;
        let email = this.state.account;
        let name = this.state.name;
        let gst = this.state.gst;

        const res = await this.loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
        );

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        var options = {
            "key": "rzp_test_Okjl451cQAmZCs",
            "amount": 0, // 2000 paise = INR 20, amount in paisa
            "name": "Merchant Name",
            'order_id': "",
            "handler": async function (response: any) {
                var values: any = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: plan_id
                }

                try {
                    await api_service
                        .post({
                            endPoint: "/v1/upgrade/payment",
                            domain: "http://44.215.80.246:4007",
                            payLoad: values
                        })
                        ?.then((res) => {


                            if (res.data.status === 200) {
                                window.open("http://44.215.80.246:3000", '_blank');


                                // window.location.href=`http://44.215.80.246:3000`;

                                //setTimeout(this.props.closeModal, 1000);

                                // //console.log("----------")
                                // //console.log(JSON.stringify(this.state.data))
                            }
                        })
                        .catch((err) => {
                            //console.log(err);
                        });
                } catch (err) {
                    console.log(err);
                }

                //   api_service.post('http://44.215.80.246:5000/upgrade/payment',values)
                //   .then(res=>{alert("Success")})
                //   .catch(e=>console.log(e))


            },
            "theme": {
                "color": "#528ff0"
            }
        };


        try {

            let payload: any = {
                attributes: {
                    plan_id: plan_id,
                    email: email,
                    "name": name,
                    "gst": gst
                },
            };


            await api_service
                .post({
                    endPoint: "/v1/generateOrderTokenRazorpay",
                    domain: "http://44.215.80.246:4007",
                    payLoad: payload
                })
                ?.then((res) => {
                    if (res.data.status === 200) {
                        alert("Success")
                        options.order_id = res.data.data.id;
                        options.amount = res.data.data.amount;
                        var rzp1 = new window.Razorpay(options);
                        rzp1.open();
                    }
                })
                .catch((err) => {
                    //console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    handlePayment = (each: any) => {
        console.log(each);
        this.setState((p) => ({
            // isAddButtonClicked: true, 
            id: each.id
        }))
        if (this.formRef.current) {
            this.formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    basic = (description: any) => {
        if (description) {
            const result = description.split('\n');
            return result?.map((each: string, index: number) => (
                <li key={index}>{each}</li>
            ));
        }

    }

    loader = () => {
        return (
            <div id="loader">
                <div id="element">
                    <div className="circ-one"></div>
                    <div className="circ-two"></div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <section className="inner-banner pricing-banner">
                    <div className="container">
                        <div className="contents">
                            <h1>Pricing</h1>
                            <p>Select Your Most Suitable Pricing to Take Your Projects to New Heights.<br />Plans to support your project requirements</p>
                        </div>
                    </div>
                </section>
                <section className="select-pricing-plan padding-lg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <h2>Select Your Most Suitable Plan or Pricing</h2>
                                <p className='justify'>Here at ConstructCoreTech, we believe in providing flexible and transparent pricing options that cater to the diverse needs of our valued customers. Our pricing model is designed to offer you unparalleled value, allowing you to choose from our three distinct plans - Basic, Premium, or Enterprise. Each plan is meticulously crafted to provide a seamless experience, whether you're managing small projects or overseeing large-scale enterprises.</p>
                                <p className='justify'>With our competitive pricing, you can gain access to a wealth of advanced features and expert support, empowering you to take charge of your construction projects with confidence. Find the perfect fit for your budget and requirements, and embark on a journey of efficiency, productivity, and success with ConstructCoreTech.</p>
                            </div>
                            <div className="col-lg-7">
                                <figure> <img src="images/select-pricing-img.png" className="img-fluid" alt="" /> </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={this.state.id ? "choose-pack padding-lg pb-0" : "choose-pack padding-lg"}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <h2>Choose a Pack to Buy</h2>
                                <p>Discover Your Perfect Pack - Basic, Premium, or Enterprise. Power Up Your Projects with ConstructCoreTech. Whether you're a startup, a growing business, or an established enterprise, we have the perfect solution for you. At ConstructCoreTech, we offer three distinct packs to cater to different project needs.</p>
                            </div>
                        </div>
                        <ul className="row">
                            {this.state.planData.map((each: any, i: any) => {
                                return (
                                    <li className="col-md" key={i}>
                                        <div className="inner">
                                            <div className={"head-block"} style={i === 2 ? { height: "206px" } : {}}>
                                                <div className="plan-title" style={{ paddingBottom: "0" }}> <span>{each.name}</span>
                                                    {/* <h3>{each.description}</h3> */}
                                                </div>
                                                {i !== 2 &&
                                                    <div className="price">
                                                        {/* <div className="graph"><span className="org"></span><span></span><span></span></div> */}
                                                        <div className="right">
                                                            {/* <div className="amt">{each.price_per_month_discount}
                                                            </div> */}


                                                            <StrikeThroughAmount amount={each.price_per_year?.toLocaleString('en-IN')} />


                                                            {each.id===1&&<div className="amt">{(each.price_per_year-(each.price_per_year*0.20))?.toLocaleString('en-IN')} INR </div>}
                                                            {each.id===2&&<div className="amt">{(each.price_per_year-(each.price_per_year*0.30))?.toLocaleString('en-IN')} INR </div>}


                                                            <div className="month">Per Month</div>
                                                        </div>
                                                    </div>}
                                                {i === 2 &&
                                                    <div className="price">
                                                        {/* <div className="graph"><span className="org"></span><span></span><span></span></div> */}
                                                        <div className="right">
                                                            <div className="amt">Contact us</div>
                                                        </div>
                                                    </div>}
                                            </div>
                                            <div className="cnt-block">
                                                <ul>
                                                    {this.basic(each.description)}
                                                </ul>
                                                <span className="you-choose">You will Save</span>
                                                <a
                                                    href="#current-openings"
                                                    onClick={() => this.handlePayment(each)}
                                                    className="btn get-started">Get Started Now</a>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </section>
                {this.state.id &&
                    <section className="need-to-discuss padding-lg" id="current-openings">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-10 head-block">
                                    {this.state.id === 1 && <h2 style={{ color: "#f29a32" }}>BASIC</h2>}
                                    {this.state.id === 2 && <h2 style={{ color: "#f29a32" }}>PREMIUM</h2>}
                                    {this.state.id === 3 && <h2 style={{ color: "#f29a32" }}>ENTERPRISE</h2>}
                                    <h4 style={{ color: "#ffffff" }}>Contact Us Today for Seamless Product Procurement!</h4>
                                    <p>Ready to enhance your operations with our product? Share your details – first name, last name, phone number, company name, and GST number – and our team will guide you through the purchase process hassle-free!</p>
                                </div>
                            </div>
                            <form onSubmit={(e) => this.handleSubmit(e)}>
                                <div className="submit-form row d-flex">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className=" "
                                            name="name"
                                            placeholder='Company Name'
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            required />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className=""
                                            required
                                            name="account"
                                            placeholder='Account Email'
                                            value={this.state.account}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className=""
                                            required
                                            name="gst"
                                            placeholder='GST'
                                            value={this.state.gst}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="col-md-12">
                                        <button type='submit' className="submit-btn">Submit Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                }
                <section className="plan-features-sec padding-lg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 text-center">
                                <h2>Compare Plans & Features</h2>
                                <p>At ConstructCoreTech, we offer three comprehensive plans to cater to your unique needs. Explore our plans below and choose the one that aligns perfectly with your project requirements.</p>
                            </div>
                        </div>
                        <div className="plan-table-holder">
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {this.state.plansData.map((each: any, index: number) => ( 
                                            <>
                                                <th key={index}>
                                                    <div className="head-block">
                                                        <span>{each.name}</span>
                                                        {index===0 && <p>Essential features to get you started</p>}
                                                        {index===1 && <p>Advanced functionalities for enhanced project management</p>}
                                                        {index===2 && <p>Large – scale projects and enterprises</p>}
                                                        <div className={index > 2 ? "price active" : "price"}>
                                                            <div style={{fontSize:"20px", paddingBottom:"10px"}}>
                                                            {/* (each.price_per_year-(each.price_per_year*0.20)) */}
                                                                {index===0&&(each.price_per_year-(each.price_per_year*0.20))?.toLocaleString('en-IN')}
                                                                {index===1&&(each.price_per_year-(each.price_per_year*0.30))?.toLocaleString('en-IN')}
                                                                {index===2&&"Contact Us"}
                                                            </div>
                                                            {index!==2?<p>Per Month</p>:<p style={{height:"18px"}}></p>}
                                                        </div>
                                                        <div className={index > 2 ? "price active" : "price"} style={ { marginTop : "2px"}}>                                                            
                                                            <div style={{fontSize:"20px", paddingBottom:"10px"}}>
                                                            {index===0 && ((each.price_per_year-(each.price_per_year*0.20))/20)?.toLocaleString('en-IN')}
                                                            {index===1 && ((each.price_per_year-(each.price_per_year*0.30))/50)?.toLocaleString('en-IN')}
                                                            {index===2 && "Submit Request"}

                                                            </div>
                                                            {index!==2?<p>Per user</p>:<p style={{height:"18px"}}></p>}
                                                        </div>
                                                    </div>
                                                </th>
                                            </>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "end" }}>Users</td>
                                        <td>20</td>
                                        <td>50</td>
                                        <td>Unlimited</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "end" }}>Storage</td>
                                        <td>5 GB</td>
                                        <td>10 GB</td>
                                        <td></td>
                                    </tr>
                                    {Object.keys(this.state.features).map((featureName, index: number) => (                                        
                                        <>
                                            {(index === 0 || index === 6 || index === 17 || index === 24) &&
                                                <tr>
                                                    {index === 0 && <td style={{ fontWeight: "bold", textAlign: "center" }}>Documents</td>}
                                                    {index === 6 && <td style={{ fontWeight: "bold", textAlign: "center" }}>Project Management</td>}
                                                    {index === 17 && <td style={{ fontWeight: "bold", textAlign: "center" }}>Planning</td>}
                                                    {index === 24 && <td style={{ fontWeight: "bold", textAlign: "center" }}>Commercial</td>}
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            }
                                            {<tr key={index}>
                                                <td style={{ textAlign: "end" }}>{featureName}</td>
                                                <td>{this.state.features[featureName].Basic && this.state.features[featureName].Basic.status === 3 ? <span className="icon-tick-circle" /> : <span className="icon-close-circle" />}</td>
                                                <td>{this.state.features[featureName].Premium && this.state.features[featureName].Premium.status === 3 ? <span className="icon-tick-circle" /> : <span className="icon-close-circle" />}</td>
                                                <td>{this.state.features[featureName].Enterprise && this.state.features[featureName].Enterprise.status === 3 ? <span className="icon-tick-circle" /> : <span className="icon-close-circle" />}</td>
                                            </tr>}
                                        </>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th style={{ padding: "0" }}></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </section>
                {/* <section className="client-speak our-features padding-lg">
                    <div className="container">
                        <div className="row justify-content-center head-block">
                            <div className="col-md-10"> <span>Our Features</span>
                                <h2>We are Awesome but<br />
                                    Don't take our words for it</h2>
                                <p className="hidden-xs">These reports provide a comprehensive overview of project status, financial health, resource utilization, and compliance. By leveraging data-driven insights, your construction management software empowers construction professionals to make informed decisions, enhance productivity, and deliver successful projects.</p>
                            </div>
                        </div>
                        <ul className="row features-listing">
                            <li className="col-md-4">
                                <div className="inner"> <span className="icon"><img src="images/features-ico1.png" alt="" /></span>
                                    <h3>Data Analytics</h3>
                                    <p>Unlock the potential of data-driven decision-making with our robust data analytics tools, providing valuable insights to optimize project performance.</p>
                                </div>
                            </li>
                            <li className="col-md-4">
                                <div className="inner"> <span className="icon"><img src="images/features-ico2.png" alt="" /></span>
                                    <h3>Fully Responsive</h3>
                                    <p>ConstructCoreTech is designed to seamlessly adapt to any device, ensuring a smooth user experience whether on desktop, tablet, or smartphone.</p>
                                </div>
                            </li>
                            <li className="col-md-4">
                                <div className="inner"> <span className="icon"><img src="images/features-ico3.png" alt="" /></span>
                                    <h3>Customer Support</h3>
                                    <p>With a dedicated support team ready to assist you, rest assured that you'll receive unparalleled customer service throughout your journey with ConstructCoreTech.</p>
                                </div>
                            </li>
                            <li className="col-md-4">
                                <div className="inner"> <span className="icon"><img src="images/features-ico4.png" alt="" /></span>
                                    <h3>Advanced Solution</h3>
                                    <p>Experience the power of advanced features and solutions in ConstructCoreTech, tailored to meet the specific demands of the construction industry.</p>
                                </div>
                            </li>
                            <li className="col-md-4">
                                <div className="inner"> <span className="icon"><img src="images/features-ico5.png" alt="" /></span>
                                    <h3>Additional Functions</h3>
                                    <p>Discover a wide range of additional functions and capabilities in ConstructCoreTech, empowering you to take control of every aspect of your construction projects</p>
                                </div>
                            </li>
                            <li className="col-md-4">
                                <div className="inner"> <span className="icon"><img src="images/features-ico6.png" alt="" /></span>
                                    <h3>Streamlined Workflows</h3>
                                    <p>Boost your team's productivity and achieve project milestones faster with ConstructCoreTech's intuitive and user-friendly interface, streamlining your project workflows</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </section> */}
                <section className="client-speak our-features padding-lg">
                    <h2 style={{ paddingBottom: "60px" }}>Return on <span style={{ fontWeight: "500" }}>Investment Calculator</span></h2>
                    <div className="container-fluid">
                        <RoiCalculator />
                    </div>
                </section>
                <section className="need-to-discuss padding-lg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 head-block">
                                <h2>Need to Discuss with Us</h2>
                                <p>Ready to take your construction projects to the next level? Reach out to us for a personalized consultation and discover how ConstructCoreTech can transform your workflows and boost your project efficiency. Let's discuss your specific requirements and explore how our solutions can meet your unique needs.</p>
                            </div>
                        </div>
                        <ContactUs />
                    </div>
                </section>
                <section className="still-hav-qtns-outer padding-lg">
                    <div className="container">
                        <h2>Still have questions?</h2>
                        <ul className="row features-listing">
                            <li className="col-md-6 equal-hight info-content team-card">
                                {/* <div className="info-content team-card">  */}
                                <span className="icon-holder"><img src="images/stil-hav-sec-icon-1.png" alt="" /></span>
                                <h3>Help Center</h3>
                                <p>Our dedicated Help Center is here to assist you every step of the way. Whether you have questions, need technical support, or want to maximize the potential of our product, we've got you covered.</p>
                                <a href="/support" className="know-more">Know more</a>
                                {/* </div> */}
                            </li>
                            <li className="col-md-6 equal-hight info-content team-card">
                                {/* <div className="info-content team-card">  */}
                                <span className="icon-holder"><img src="images/stil-hav-sec-icon-2.png" alt="" /></span>
                                <h3>Faqs</h3>
                                <p>Our comprehensive FAQ is designed to support your journey with ease and clarity.</p>
                                <a href="/faq" className="know-more">Know more</a>
                                {/* </div> */}
                            </li>
                        </ul>
                    </div>
                </section>
                {/* <section className="signup-outer gradient-bg padding-lg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <ul className="clearfix">
                                    <li> <span className="icon-men"></span>
                                        <h4>Signup for an <span>Account</span></h4>
                                    </li>
                                    <li> <span className="icon-chat"></span>
                                        <h4>Discuss with <span>our team</span></h4>
                                    </li>
                                    <li> <span className="icon-lap"></span>
                                        <h4>Receive a <span>good support</span></h4>
                                    </li>
                                </ul>
                                <div className="signup-form">
                                    <form action="#" method="get">
                                        <div className="email">
                                            <input name="email" type="text" placeholder="email" />
                                        </div>
                                        <div className="password">
                                            <input name="password" type="password" placeholder="password" />
                                        </div>
                                        <button className="signup-btn">Sign up Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {this.state.isAddButtonClicked && (
                    <UserFormModal
                        id={this.state.id}
                        // editData={this.state.editData}
                        open={this.state.isAddButtonClicked}
                        closeModal={() => this.closeModal()}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(Pricing);