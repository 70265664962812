import React, { Component } from 'react'
import { api_service } from '../../api/api_service';

export default class ContactList extends Component {

        state = {
          dbdata: [],
        };

    getContactPayload() {
        let payload: any = {
          attributes: {
            page: 1,
            limit: 500,
          },
        };
        return payload;
      }

    async fetchData(){
        try {
            await api_service
                .get({
                    endPoint: api_service.api_urls.CONTACTUS,
                    domain: "http://44.215.80.246:4000"
                })
                ?.then((res) => {
                    console.log(res.data)
                    if (res.data.status === 200) {
                        console.log(res.data.status);
                        this.setState({dbdata:res.data.data})
                    }
                })
                .catch((err: any) => {
                    console.log(err.message);
                });
        } catch (error: any) {
            console.log(error.message);

        }
    }

    async componentDidMount() {

        // const res = await this.loadScript(
        //     'https://checkout.razorpay.com/v1/checkout.js'
        //   );

        //   if (!res) {
        //     alert('Razorpay SDK failed to load. Are you online?');
        //     return;
        //   }else{
        //     //alert("loaded")
        //   }
        // const script = document.createElement("script");

        // script.src = "https://checkout.razorpay.com/v1/checkout.js";
        // script.async = true;

        // document.body.appendChild(script);

    }

    render() {
        return (
            <div className="dashboard_table">
                <div className="header_section">
                    <div className="title">Contacts</div>
                    {/* <div className="options_section">
                        <div className="input-group search_bar">
                            <span className="input-group-text">
                                <img src={searchIcon}></img>
                            </span>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search Projects..."
                            />
                        </div>
                        <button
                            className=""
                            onClick={() =>
                                this.setState((p) => ({ isAddButtonClicked: true }))
                            }
                        >
                            <img src={addIcon}></img>
                        </button>
                        <div className="nav-item dropdown">
                            <button
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className="filter_btn"
                            >
                                <img
                                    //   onClick={() => this.setState((p) => ({ click: !p.click }))}
                                    src={filter}
                                    alt="..."
                                    className="dropdown-toggle"
                                />
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-end animate slideIn"
                                aria-labelledby="navbarDropdown"
                            >
                                <a className="dropdown-item" href="#">
                                    All
                                </a>
                                <a className="dropdown-item" href="#">
                                    Admin
                                </a>
                                <a className="dropdown-item" href="#">
                                    Teams
                                </a>
                            </div>
                        </div>
                        <button className="" disabled={disableButton}>
                            <img src={shareIcon}></img>
                        </button>
                        <button className="" disabled={disableButton}>
                            <img src={printIcon}></img>
                        </button>
                    </div> */}
                </div>
                <div className="table-responsive table_section">
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th>
                                    <div className="checkbox_div">
                                        <input
                                            id="checkbox"
                                            className="checkbox-custom"
                                            name="checkbox"
                                            type="checkbox"
                                            checked={
                                                !this.state.dbdata?.some(
                                                    (rows) => rows.checked !== true
                                                )
                                            }
                                            onChange={(e: any) => this.handleAllselect(e)}
                                        />
                                        <label
                                            htmlFor="checkbox"
                                            className="checkbox-custom-label"
                                        ></label>
                                    </div>
                                    <label className="id">S.No</label>
                                </th> */}
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Company</th>
                                <th>Phone Number</th>
                                <th>Business Mail</th>
                                <th>Job Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state?.dbdata.map((each:any, index) => {
                                return (
                                    <>
                                        {/* <div className="checkbox_div">
                                            <input
                                                id={`checkbox${index}`}
                                                className="checkbox-custom"
                                                name="checkbox"
                                                type="checkbox"
                                                checked={each.checked}
                                                onChange={(e) => this.handleSelectEachRow(e, each)}
                                            />
                                            <label
                                                htmlFor={`checkbox${index}`}
                                                className="checkbox-custom-label"
                                            ></label>
                                        </div> */}
                                        <tr
                                            key={index}
                                            className={index % 2 === 0 ? "color" : "nocolor"}
                                        >
                                            {/* <td>
                                                <label></label>
                                            </td> */}
                                            <td>{each.first_name }</td>
                                            <td>{each.last_name }</td>
                                            <td>{each.company }</td>
                                            <td>{each.phone_num }</td>
                                            <td>{each.buss_email }</td>
                                            <td>{each.job_title }</td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
