import React, { Component } from 'react'
import { jobTypes } from '../utils/ApplyJob';

export default class ApplyJob extends Component {
    state = {
        jobDetails: {} as any
    }
    queryParams: any = new URLSearchParams(window.location.search)
    root: any = this.queryParams.get("role");

    componentDidMount(): void {
        const data = jobTypes[this.root]
        this.setState({ jobDetails: data })
    }

    render() {
        console.log(this.state);
        const { jobDetails } = this.state
        return (
            <div>
                {/* <!-- ==============================================**Inner Banner**=================================================== --> */}

                <section className="inner-banner career-banner apply-job">

                    <div className="container">

                        <div className="contents">

                            <h1>{jobDetails.title}</h1>

                            <p><span className="icon-map-marker"></span>Hyderabad</p>

                            <a href="#apply-job" className="btn orange scroll-link">Apply Now</a> </div>

                    </div>

                </section>


                {/* <!-- ==============================================**Job features**=================================================== --> */}

                <section className="job-apply-wrapper padding-lg">

                    <div className="container">

                        <div className="job-info-box">

                            <p>{jobDetails.description}</p>

                        </div>

                        <div className="job-features-wrapper">

                            <h5>A Typical Week</h5>

                            <ul className="icon-tik-list">

                                {jobDetails?.typicalWeek?.map((each: any) => {
                                    return <li><p style={{ textAlign: "justify" }}>{each}</p></li>
                                })}

                            </ul>

                        </div>

                        <div className="job-features-wrapper">

                            <h5>Requirements</h5>

                            <ul className="icon-tik-list">

                                {jobDetails?.requirements?.map((each: any) => {
                                    return <li><p style={{ textAlign: "justify" }}>{each}</p></li>
                                })}

                            </ul>

                        </div>

                        <div className="job-features-wrapper">

                            <h5>Bonus Points</h5>

                            <ul className="icon-tik-list">

                                {jobDetails?.bonusPoints?.map((each: any) => {
                                    return <li><p style={{ textAlign: "justify" }}>{each}</p></li>
                                })}

                            </ul>

                        </div>

                    </div>

                </section>



                {/* <!-- ==============================================**Job Apply**=================================================== --> */}

                <section className="apply-for-job-outer padding-lg" id="apply-job">

                    <div className="container">

                        <div className="row justify-content-center top-intro">

                            <div className="col-md-10">

                                <h2>Apply for this Job</h2>

                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since beenLorem Ipsum is simply dummy text of the printing and typesetting industry. </p>

                            </div>

                        </div>

                        <div className="contact-form-wrapper">

                            <form>

                                <div className="row">

                                    <div className="col-md-6">

                                        <label>First Name</label>

                                        <input name="first_name" placeholder="" type="text" />

                                    </div>

                                    <div className="col-md-6">

                                        <label>Last Name</label>

                                        <input name="last_name" placeholder="" type="text" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-6">

                                        <label>Email</label>

                                        <input name="Email" placeholder="" type="text" />

                                    </div>

                                    <div className="col-md-6">

                                        <label>Phone</label>

                                        <input name="Phone" placeholder="" type="text" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-6">

                                        <label>Location</label>

                                        <textarea placeholder="" className="large"></textarea>

                                    </div>

                                    <div className="col-md-6">

                                        <div className="row">

                                            <div className="col-md-12">

                                                <label>Linkedin Profile</label>

                                                <input name="Linkedin_profile" placeholder="" type="text" />

                                            </div>

                                            <div className="col-md-12">

                                                <label>Website</label>

                                                <input name="website" placeholder="" type="text" />

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-12">

                                        <label>Choose File</label>

                                        <div className="browse-doc">

                                            <input type="text" id="browse-path" />

                                            <label className="browse-icon"><span className="icon-file-upload"></span><span>

                                                <input type="file" id="browse-file" name="myfile" />

                                            </span> </label>

                                        </div>

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-12">

                                        <label>Message</label>

                                        <textarea placeholder=""></textarea>

                                    </div>

                                    <div className="col-md-12">

                                        <button type='button' className="btn submit">Submit Resume</button>

                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>

                </section>



                {/* <!-- ==============================================**Signup Section**=================================================== --> */}

                {/* <section className="signup-outer gradient-bg padding-lg">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-md-10">

                                <ul className="clearfix">

                                    <li> <span className="icon-men"></span>

                                        <h4>Signup for an <span>Account</span></h4>

                                    </li>

                                    <li> <span className="icon-chat"></span>

                                        <h4>Discuss with <span>our team</span></h4>

                                    </li>

                                    <li> <span className="icon-lap"></span>

                                        <h4>Receive a <span>good support</span></h4>

                                    </li>

                                </ul>

                                <div className="signup-form">

                                    <form action="#" method="get">

                                        <div className="email">

                                            <input name="email" type="text" placeholder="email" />

                                        </div>

                                        <div className="password">

                                            <input name="password" type="password" placeholder="password" />

                                        </div>

                                        <button className="signup-btn">Sign up Now</button>

                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>

                </section> */}
            </div>
        )
    }
}
