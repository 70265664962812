import Dashboard from "../../../assets/images/Dashboard.svg"
import Documents from "../../../assets/images/Documents.svg"
import ProjectManagement from "../../../assets/images/ProjectManagement.svg"

export const items =[
  {
    "text": "User Managment",
    "icon": Dashboard,
    "path":"usermanagment"
  },
  {
    "text": "Projects",
    "icon": Documents,
    "path": "projects"
  },
  {
    "text": "Contact Us",
    "icon": ProjectManagement,
    "path": "contact-list"
  }
  
]