import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactUs from '../components/common/ContactUs';
import withRouter from '../components/common/withRoter';
import { api_service } from '../api/api_service';
import Typed from "typed.js";



declare global {
    interface JQuery<TElement = HTMLElement> {
        owlCarousel(options?: any): JQuery<TElement>;
    }
}

declare global {
    interface Window {
        api?: any;
    }
}

declare global {
    interface Window {
        // ⚠️ notice that "Window" is capitalized here
        Razorpay: any;
    }
}

interface ILoginProps {
    history?: any;
    match?: any;
    isLoggedIn?: boolean;
    isCollobra?: boolean;

}

class Home extends Component<ILoginProps, {}> {

    constructor(ILoginProps: any) {
        super(ILoginProps);
        this.openPayModal = this.openPayModal.bind(this);
        //     <Helmet>
        //     <script src="../js/custom.js" type="text/javascript" />
        //   </Helmet>
    }

    state = {
        id: "",
        planData: [{ features: [] }],
    }

    async loadScript(src: any) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async openPayModal(plan_id: any) {
        // var amount = amt * 100; //Razorpay consider the amount in paise
        var email = prompt('Please enter your email');

        const res = await this.loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
        );

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        var options = {
            "key": "rzp_test_Okjl451cQAmZCs",
            "amount": 0, // 2000 paise = INR 20, amount in paisa
            "name": "Merchant Name",
            'order_id': "",
            "handler": async function (response: any) {
                console.log(response);
                var values: any = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: plan_id,
                }

                try {
                    await api_service
                        .post({
                            endPoint: "/v1/upgrade/payment",
                            domain: "http://44.215.80.246:4007",
                            payLoad: values
                        })
                        ?.then((res) => {


                            if (res.data.status === 200) {
                                alert("Success")
                                // //console.log("----------")
                                // //console.log(JSON.stringify(this.state.data))
                            }
                        })
                        .catch((err) => {
                            //console.log(err);
                        });
                } catch (err) {
                    console.log(err);
                }

                //   api_service.post('http://44.215.80.246:5000/upgrade/payment',values)
                //   .then(res=>{alert("Success")})
                //   .catch(e=>console.log(e))


            },
            "theme": {
                "color": "#528ff0"
            }
        };


        try {

            let payload: any = {
                attributes: {
                    plan_id: plan_id,
                    email: email
                },
            };


            await api_service
                .post({
                    endPoint: "/v1/generateOrderTokenRazorpay",
                    domain: "http://44.215.80.246:4007",
                    payLoad: payload
                })
                ?.then((res) => {

                    console.log(res.data.data)
                    if (res.data.status === 200) {
                        alert("Success")

                        options.order_id = res.data.data.id;
                        options.amount = res.data.data.amount;
                        console.log(options)
                        var rzp1 = new window.Razorpay(options);

                        rzp1.open();


                        // //console.log("----------")
                        // //console.log(JSON.stringify(this.state.data))

                    }
                })
                .catch((err) => {
                    //console.log(err);
                });
        } catch (err) {
            console.log(err);
        }


        // axios.post('http://44.215.80.246:5000/upgrade/order',{amount:amount})
        // .then(res=>{
        //     options.order_id = res.data.id;
        //     options.amount = res.data.amount;
        //     console.log(options)
        //     var rzp1 = new window.Razorpay(options);
        //     rzp1.open();
        // })
        // .catch(e=>console.log(e))

    };

    async componentDidMount() {
        // window.location.reload()
        //   const script = document.createElement("script");
        //   script.src = "../js/custom.js";
        //   script.async = true;

        //   document.body.appendChild(script);

        // const res = await this.loadScript(
        //     'https://checkout.razorpay.com/v1/checkout.js'
        //   );

        //   if (!res) {
        //     alert('Razorpay SDK failed to load. Are you online?');
        //     return;
        //   }else{
        //     //alert("loaded")
        //   }
        // const script = document.createElement("script");

        // script.src = "https://checkout.razorpay.com/v1/checkout.js";
        // script.async = true;

        // document.body.appendChild(script);
        var $element = $('.custom-opacity .owl-carousel');
        if ($element.length > 0) {
            $element.owlCarousel({
                navText: ['<i class="icon-know-more-arrow" ></i>', '<i class="icon-know-more-arrow " ></i>'],
                nav: true,
                margin: 30,
                autoplay: true,
                smartSpeed: 1000,
                autoplayHoverPause: true,
                scrollPerPage: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 3,
                        center: true,
                        margin: 20
                    },
                }
            });
        }
        var $element = $('.brands .owl-carousel');
        if ($element.length > 0) {
            $element.owlCarousel({
                loop: true,
                margin: 30,
                dots: false,
                autoplay: true,
                smartSpeed: 1000,
                autoplayHoverPause: true,
                responsive: {
                    0: {
                        items: 2
                    },
                    480: {
                        items: 3,
                        margin: 20
                    },
                    768: {
                        items: 4,
                        margin: 20
                    },
                    1024: {
                        items: 5,
                        margin: 30
                    },
                    1200: {
                        items: 6,
                        margin: 30
                    },
                }
            });
        }

        var $element = $('#typed');
        var $element1 = $('#typed1');
        var $element2 = $('#typed2');

        if ($element1.length > 0) {
            new Typed('#typed1', {
                strings: [
                    "ost Management, Control, Coordination",
                    "ollaborate with your team with ease",
                    "oordinating tasks with ease",
                    "loud-based software, easily accessible",
                    "entralized data, at your fingertips",
                    "ost management, at your fingertips",
                    "oordinated efforts to succeed",
                    "ommunication made seamless with the software"
                ],
                typeSpeed: 55,
                backSpeed: 55,
                smartBackspace: true,
                loop: true
            });
        }

        if ($element2.length > 0) {
            new Typed('#typed2', {
                strings: [
                    "ime Tracking, Team Management, Transparency",
                    "rack your progress in real-time",
                    "racking progress, day by day",
                    "echnology that streamlines your day",
                    "echnology that paves the way",
                    "eamwork that drives success",
                    "echnology that empowers progress",
                    "ime-saving tools for project management"
                ],
                typeSpeed: 55,
                backSpeed: 55,
                smartBackspace: true,
                loop: true
            });
        }

        if ($element.length > 0) {
            new Typed('#typed', {
                strings: [
                    "ollaboration, Coordination, Communication",
                    "onstruction tasks made easy with technology",
                    "ontrol your projects with ease",
                    "onnecting teams with ease",
                    "onstruction management made simple",
                    "omplete control of your projects",
                    "ollaboration made efficient",
                    "onstruction projects managed with ease"
                ],
                typeSpeed: 55,
                backSpeed: 55,
                smartBackspace: true,
                loop: true
            });
        }

    }

    getplanspayload() {
        let payload: any = {
            attributes: {
                role_id: 1,
                page: 1,
                limit: 20,
            },
        };
        return payload;
    }

    fetchData = async () => {
        try {
            await api_service
                .get({
                    endPoint: api_service.api_urls.FETCH_PLANS + "?role_id=1",
                    domain: "http://44.215.80.246:4007"
                })
                ?.then((res) => {
                    if (res.data.status === 200) {
                        console.log(JSON.stringify(res.data.data));

                        this.setState({ planData: res.data.data });

                        // //console.log("----------")
                        // //console.log(JSON.stringify(this.state.data))

                    }
                })
                .catch((err) => {
                    //console.log(err);
                });
        } catch (err) {
            //console.log(err);
        }
    };

    onClick = (e: any) => {

        console.log(e)
        return e;

    }

    render() {
        return (
            <div>
                {/* <!-- ==============================================**Banner opt5**=================================================== --> */}

                <div className="banner slide3 banner5">

                    <div className="container">

                        <div className="row cnt-block">

                            <div className="col-md-12">

                                <div className="left">
                                    <div style={{ paddingBottom: "40px" }}>

                                        <p style={{ textAlign: "left", paddingLeft: "30%", paddingBottom:"10px" }}>
                                            <span className="fixed-txt" style={{ fontWeight: "bold", color: "#14213C" }}>C </span>
                                            <span id="typed"></span>
                                        </p>

                                        <p style={{ textAlign: "left", paddingLeft: "30%", paddingBottom:"10px" }}>
                                            <span className="fixed-txt" style={{ fontWeight: "bold", color: "#5F84C0" }}>C </span>
                                            <span id="typed1"></span>
                                        </p>

                                        <p style={{ textAlign: "left", paddingLeft: "30%", paddingBottom:"10px" }}>
                                            <span className="fixed-txt" style={{ fontWeight: "bold", color: "#C00000" }}>T </span>
                                            <span id="typed2"></span>
                                        </p>
                                    </div>
                                    <a href="/pricing" className="get-started">Purchase Now</a> 

                                    <p style={{paddingTop:"10px"}}>The Perfect Construction Solution Software</p> </div>

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-12">

                                <figure><img src="images/banner-slide3.png" className="img-fluid" alt="" /></figure>

                            </div>

                        </div>

                    </div>

                </div>



                {/* <!-- ==============================================**How it Works**=================================================== --> */}

                <section className="how-it-works-sec">

                    <div className="sided-item-wrapper padding-lg">

                        <div className="container">

                            <h2>Why Choose <span style={{ color: "#14213C" }}>Construct</span><span style={{ color: "#5F84C0" }}>core</span><span style={{ color: "#C00000" }}>tech</span> ?</h2>

                            <div className="row">

                                <div className="col-md-9 content-area">

                                    <ul className="row marketing-list3">

                                        <li className="col-md-6 equal-hight">

                                            <div className="ico-block"><img src="images/link-building-ico.png" className="rounded-circle img-fluid" alt="" /></div>

                                            <div className="cnt-block">

                                                <h3>Improved data management</h3>

                                                <p style={{ textAlign: "justify" }}>Construction cloud solutions centralize data and information, making it easier to access and analyze data for informed decision making.</p>
                                            </div>

                                        </li>

                                        <li className="col-md-6 equal-hight">

                                            <div className="ico-block"><img src="images/seo-succes-ico.png" className="rounded-circle img-fluid" alt="" /></div>

                                            <div className="cnt-block">

                                                <h3>Better collaboration</h3>

                                                <p style={{ textAlign: "justify" }}>Manages effectively consultants, contractors, suppliers and vendors, improving communication and reducing the risk of miscommunication.</p>
                                            </div>

                                        </li>

                                        <li className="col-md-6 equal-hight">

                                            <div className="ico-block"><img src="images/audience-ico.png" className="rounded-circle img-fluid" alt="" /></div>

                                            <div className="cnt-block">

                                                <h3>Cloud solutions</h3>

                                                <p style={{ textAlign: "justify" }}>Allow construction professionals to access project data and information from anywhere, improving collaboration and enabling remote work.</p>
                                            </div>

                                        </li>

                                        <li className="col-md-6 equal-hight">

                                            <div className="ico-block"><img src="images/privacy-ico.png" className="rounded-circle img-fluid" alt="" /></div>

                                            <div className="cnt-block">

                                                <h3>Real-time monitoring </h3>

                                                <p style={{ textAlign: "justify" }}>Project progress and performance, allowing for early identification of issues and faster resolution.
                                                </p>
                                            </div>

                                        </li>
                                        <li className="col-md-6 equal-hight">

                                            <div className="ico-block"><img src="images/Increased-Transparency.png" className="rounded-circle img-fluid" alt="" /></div>

                                            <div className="cnt-block">

                                                <h3>Increased transparency</h3>

                                                <p style={{ textAlign: "justify" }}>Visibility into project progress and performance, improving accountability and reducing the risk of disputes.</p>
                                            </div>

                                        </li>

                                        <li className="col-md-6 equal-hight">

                                            <div className="ico-block"><img src="images/Optimized-Performance.png" className="rounded-circle img-fluid" alt="" /></div>

                                            <div className="cnt-block">

                                                <h3>Optimized Performance</h3>

                                                <p style={{ textAlign: "justify" }}>Automate repetitive tasks and reduce manual errors, resulting in improved efficiency and productivity.</p>
                                            </div>

                                        </li>

                                    </ul>

                                </div>

                            </div>

                        </div>

                        <div className="right-sided-full-image">

                            <figure><img src="images/how-it-works-marketing.png" alt="" /></figure>

                        </div>

                    </div>

                </section>



                {/* <!-- ==============================================**Create SEO Reports**=================================================== --> */}

                <section className="seo-reports">

                    <div className="sided-item-wrapper">

                        <div className="container">

                            <div className="row justify-content-end">

                                <div className="col-md-7">

                                    <div className="content-area padding-lg" style={{ paddingBottom: "0" }}>

                                        <h2>Comprehensive Project Status <br /> Overview</h2>

                                        <p style={{ textAlign: "justify" }}>These reports provide a comprehensive overview of project status, financial health, resource utilization, and compliance. By leveraging data-driven insights, your construction management software empowers construction professionals to make informed decisions, enhance productivity, and deliver successful projects..</p>

                                        {/* <ul className="icon-tik-list row">

                                        <li className="col-md-6">

                                            <p>Lorem Ipsum is simply dummy text of the printing typesetting.</p>

                                        </li>

                                        <li className="col-md-6">

                                            <p>Lorem Ipsum is simply dummy text of the printing typesetting.</p>

                                        </li>

                                        <li className="col-md-6">

                                            <p>Lorem Ipsum is simply dummy text of the printing typesetting.</p>

                                        </li>

                                        <li className="col-md-6">

                                            <p>Lorem Ipsum is simply dummy text of the printing typesetting.</p>

                                        </li>

                                    </ul> */}
                                        <OwlCarousel className='owl-theme' margin={10} loop
                                            autoplay={true}
                                            autoplayTimeout={5000}
                                            nav
                                            dots={false}
                                            responsive={{
                                                0: {
                                                    items: 1
                                                },
                                                768: {
                                                    items: 1
                                                },
                                                992: {
                                                    items: 1
                                                }
                                            }}
                                        >
                                            <div className='item'>
                                                <ul className="icon-tik-list">
                                                    <li>
                                                        <p style={{ textAlign: "justify" }}>Project Progress Reports:<br />
                                                            Track and visualize the progress of your construction projects with comprehensive reports. Get real-time updates on tasks completed, milestones achieved, and potential delays.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p style={{ textAlign: "justify" }}>Financial Analysis Reports:<br />
                                                            Gain valuable insights into project finances with financial analysis reports. Monitor budgets, expenses, and cost projections to ensure projects stay on track financially. </p>
                                                    </li>
                                                    <li>

                                                        <p style={{ textAlign: "justify" }}>Resource Utilization Reports:<br />
                                                            Optimize resource allocation and ensure efficient utilization of manpower and equipment. Resource utilization reports provide data on workforce allocation and equipment usage.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='item'>
                                                <ul className="icon-tik-list">
                                                    <li>

                                                        <p style={{ textAlign: "justify" }}>Safety and Compliance Reports:<br />
                                                            Ensure a safe and compliant construction site with safety and compliance reports. Monitor adherence to safety protocols and regulatory requirements for a secure working environment.</p>
                                                    </li>

                                                    <li>

                                                        <p style={{ textAlign: "justify" }}>Document Management Reports:<br />
                                                            Stay organized with document management reports that track document status, approvals, and revisions. Access critical project documents with ease and maintain version control.</p>
                                                    </li>

                                                    <li>

                                                        <p style={{ textAlign: "justify" }}>Submittals and Material Inspection Reports:<br />
                                                            Streamline the submittal and material inspection process with detailed reports. Monitor the status of submittals, approvals, and material inspections to avoid delays.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='item'>
                                                <ul className="icon-tik-list">
                                                    <li>
                                                        <p style={{ textAlign: "justify" }}>Project Communication Reports:<br />
                                                            Ensure effective communication with stakeholders through project communication reports. Track meeting minutes, letters, and emails exchanged for transparent communication.</p>
                                                    </li>

                                                    <li>
                                                        <p style={{ textAlign: "justify" }}>Daily Activity Reports:<br />
                                                            Get a snapshot of daily activities on the construction site with daily activity reports. Monitor manpower, machinery, activities, and safety compliance on a daily basis.</p>
                                                    </li>

                                                    <li>
                                                        <p style={{ textAlign: "justify" }}>Performance Analytics Reports:<br />
                                                            Evaluate project performance and identify areas for improvement with performance analytics reports. Analyze key performance indicators (KPIs) to optimize project outcomes.</p>

                                                    </li>
                                                </ul>
                                            </div>

                                        </OwlCarousel>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="left-sided-full-image">

                            <figure><img src="images/seo-reports-3.png" alt="" /></figure>

                        </div>

                    </div>

                </section>



                {/* <!-- ==============================================**Generate Forms**=================================================== --> */}

                <section className="generate-forms padding-lg">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-md-10">

                                <h2>Generate different types of Forms</h2>

                                <p>"Streamline your project documentation with ease by effortlessly creating a wide range of forms tailored to your unique project requirements. ConstructCoreTech simplifies form generation, saving you valuable time and effort while ensuring accurate and compliant documentation."</p>

                            </div>

                        </div>

                    </div>

                    <div className="custom-opacity">

                        <div id="owl-career" className="owl-carousel">

                            <div className="item">

                                <figure><img src="images/custom-opacity-slider1.jpg" alt="" /></figure>

                            </div>

                            <div className="item">

                                <figure><img src="images/custom-opacity-slider2.jpg" alt="" /></figure>

                            </div>

                            <div className="item">

                                <figure><img src="images/custom-opacity-slider3.jpg" alt="" /></figure>

                            </div>

                            <div className="item">

                                <figure><img src="images/custom-opacity-slider2.jpg" alt="" /></figure>

                            </div>

                        </div>

                        <div className="keyboard">

                            <figure><img src="images/key-board-mouse.png" className="img-fluid" alt="" /></figure>

                        </div>

                        <div className="frame">

                            <figure><img src="images/slider-frame.png" className="img-fluid" alt="" /></figure>

                        </div>

                    </div>

                </section>



                {/* <!-- ==============================================**Provide Features**=================================================== --> */}

                <section className="provide-features">

                    <div className="sided-item-wrapper">

                        <div className="container">

                            <div className="row">

                                <div className="col-md-8">

                                    <div className="content-area padding-lg">

                                        <h2>FEATURES DON’T GROW BUSINESSES<br />

                                            "SOLUTIONS DOES"</h2>

                                        <ul className="row features-listing">

                                            <li className="col-md-6"> <span className="icon"><img src="images/Documents.png" alt="" /></span>
                                                <h3>Document Management</h3>
                                                <p style={{ textAlign: "justify" }}>Streamline your construction documentation process with our advanced document management module. Easily upload, share, and track project drawings and submittals, ensuring all stakeholders stay on the same page.</p>
                                            </li>
                                            <li className="col-md-6"> <span className="icon"><img src="images/Project-Management.png" alt="" /></span>
                                                <h3>Project Management</h3>
                                                <p style={{ textAlign: "justify" }}>Take full control of your projects with our comprehensive project management module. From letters to minutes of meetings, manage all project communication seamlessly. Utilize material inspection reports, request for information, and work inspection requests to ensure quality assurance.</p>
                                            </li>
                                            <li className="col-md-6"> <span className="icon"><img src="images/Planning.png" alt="" /></span>
                                                <h3>Planning</h3>
                                                <p style={{ textAlign: "justify" }}>Optimize project planning and scheduling with our user-friendly Gantt charts and planning tools. Efficiently allocate manpower, machinery, and activities for smoother project execution. Get real-time updates with daily reports on manpower, activity progress, and safety measures.</p>
                                            </li>
                                            <li className="col-md-6"> <span className="icon"><img src="images/Commercial.png" alt="" /></span>
                                                <h3>Commercial</h3>
                                                <p style={{ textAlign: "justify" }}>Simplify commercial aspects of your projects with our commercial management module. Keep track of contracts, bonds, insurances, and payment invoicing effortlessly. Generate taking over certificates and finalize project accounts with ease.</p>
                                            </li>

                                        </ul>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="right-sided-full-image">

                            <figure><img src="images/provide-feature.png" alt="" /></figure>

                        </div>

                    </div>

                </section>



                {/* <!-- ==============================================**Latest Stories**=================================================== --> */}

                <section className="latest-stories faq-sec padding-lg white-bg">

                    <div className="container">

                        <div className="row justify-content-center head-block">

                            <div className="col-md-10"> <span>FAQ</span>

                                <h2>Frequently Asked Questions</h2>

                            </div>

                        </div>

                        <ul className="row">

                            <li className="col-md-6">

                                <h3>What is ConstructCoreTech's construction management software?</h3>

                                <p style={{ textAlign: "justify" }}>ConstructCoreTech offers an advanced construction management software that helps streamline project processes, improve collaboration, and enhance overall project efficiency.</p>

                            </li>

                            <li className="col-md-6">

                                <h3>How does ConstructCoreTech handle project management tasks?</h3>

                                <p style={{ textAlign: "justify" }}>Our software comes with robust project management tools that assist in task assignment, progress tracking, issue resolution, and effective communication among team members.</p>

                            </li>

                            <li className="col-md-6">

                                <h3>What are the benefits of upgrading to the Premium plan?</h3>

                                <p style={{ textAlign: "justify" }}>Upgrading to the Premium plan unlocks advanced functionalities, including enhanced reporting, resource planning, and collaboration tools for seamless project management.</p>

                            </li>

                            <li className="col-md-6">

                                <h3>Can I switch between plans if my requirements change?</h3>

                                <p style={{ textAlign: "justify" }}>Absolutely! You have the flexibility to upgrade or downgrade your plan based on your evolving project needs and business requirements.</p>

                            </li>

                        </ul>

                    </div>

                </section>



                {/* <!-- ==============================================**Need to Discuss**=================================================== --> */}

                <section className="need-to-discuss bg-img padding-lg">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-md-10 head-block">

                                <h2>Need to Discuss with Us</h2>

                                <p>Ready to take your construction projects to the next level? Reach out to us for a personalized consultation and discover how ConstructCoreTech can transform your workflows and boost your project efficiency. Let's discuss your specific requirements and explore how our solutions can meet your unique needs.</p>

                            </div>

                        </div>

                        {/* <div className="submit-form row d-flex">

                        <div className="col-md-6">

                            <input name="First Name" placeholder="First Name" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Last Name" placeholder="Last Name" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Company" placeholder="Company" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Phone Number" placeholder="Phone Number" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Business Mail" placeholder="Business Mail" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Job Title" placeholder="Job Title" type="text" />

                        </div>

                        <div className="col-md-12">

                            <button className="submit-btn">Submit Now</button>

                        </div>

                    </div> */}
                        <ContactUs />

                    </div>

                </section>


                {/* <!-- ==============================================**Partners**=================================================== --> */}

                <section className="brands">

                    <div className="container">

                        <ul className="owl-carousel clearfix">

                            <li><a href="#"><img src="images/brand1.jpg" className="img-fluid" alt="" /></a></li>

                            <li><a href="#"><img src="images/brand2.jpg" className="img-fluid" alt="" /></a></li>

                            <li><a href="#"><img src="images/brand3.jpg" className="img-fluid" alt="" /></a></li>

                            <li><a href="#"><img src="images/brand4.jpg" className="img-fluid" alt="" /></a></li>

                            <li><a href="#"><img src="images/brand5.jpg" className="img-fluid" alt="" /></a></li>

                            <li><a href="#"><img src="images/brand6.jpg" className="img-fluid" alt="" /></a></li>

                        </ul>

                    </div>

                </section>
            </div>
        )
    }
}

export default withRouter(Home);