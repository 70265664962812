import { Component } from "react";
import "./navbarr.css";
import Bell from "../../assets/images/BellIcon.svg";
import Settings from "../../assets/images/SettingsIcon.svg";
import Logout from "../auth/logout";

export default class Navbarr extends Component<
  { history?: any },
  { click: boolean }
> {
  state = {
    click: false,
  };

  render() {
    return (
      <div className="d-flex justify-content-end user-login-navbar">
        <div>
          <img src={Bell}></img>
        </div>
          <div className="nav-item dropdown">
            <img
              src={Settings}
              alt="..."
              // className="rounded-circle border-left nav-link dropdown-toggle"
              // id="navbarDropdown"
              // role="button"
              // data-bs-toggle="dropdown"
              // aria-haspopup="true"
              // aria-expanded="false"
              // type="button"
              className="dropdown-toggle"
               id="dropdownMenu2"
                data-toggle="dropdown"
                 aria-haspopup="true"
                  aria-expanded="false"
            />
            <div
              className="dropdown-menu nav_dropdown dropdown-menu-end animate slideIn"
              aria-labelledby="navbarDropdown2"
            >
              <a className="dropdown-item" href="usermanagment">
                User Managment
              </a>
              <a className="dropdown-item" href="#">
                IND
              </a>
            </div>
        </div>

        <div className="border-start border-success profil_pic">
          <div className="dropdown">
            <img
              onClick={() => this.setState((p) => ({ click: !p.click }))}
              src={process.env.PUBLIC_URL + "logo192.png"}
              alt="..."
              // className="ms-3 rounded-circle border-left nav-link dropdown-toggle"
              // id="navbarDropdown"
              // role="button"
              // data-bs-toggle="dropdown"
              // aria-haspopup="true"
              // aria-expanded="false"
              className="dropdown-toggle"
               id="dropdownMenu2"
                data-toggle="dropdown"
                 aria-haspopup="true"
                  aria-expanded="false"
              width="36"
              height="36"
            />
            <div
              // className="dropdown-menu dropdown-menu-end animate slideIn"
              // aria-labelledby="navbarDropdown"
              className="dropdown-menu nav_dropdown dropdown-menu-end animate slideIn"
              aria-labelledby="navbarDropdown2"
            >
              <a className="dropdown-item" href="#">
                Action
              </a>
              <a className="dropdown-item" href="#">
                Another action
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">
                <Logout />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
