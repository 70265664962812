import React, { Component } from 'react'
import withRouter from '../../components/common/withRoter'

 class Logout extends Component<{history?:any},{}> {
logout(){
    if (window.confirm('Do you want to proceed?')) {
        sessionStorage.removeItem('user')
        this.props.history('/',{replace:true})
      } else {
        
      }
}
  render() {
    return (
      <button className='popuptext' onClick={()=>this.logout()}>
          Logout
      </button>
    )
  }
}

export default withRouter( Logout)