import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

 const withRouter = (Component:any) => {
	const Wrapper = (props:any) => {
		const history = useNavigate();
		const location = useLocation();
		return <Component history={history} location={location} {...props} />;
	}; 
	return Wrapper;
};

export default withRouter