import React from 'react'

export default function Support() {
    return (
        <div>

            <section className="inner-banner">

                <div className="container">

                    <div className="contents">

                        <h1>Support</h1>

                        <p>Welcome to our Support Hub, your trusted companion on every step of your project journey. Our dedicated team is committed to providing comprehensive assistance, ensuring that you navigate challenges with confidence and achieve success. From troubleshooting technical issues to offering expert guidance, we're here to empower you and your projects.</p>

                    </div>

                </div>

            </section>

            <section className="contact-outer padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-12 col-lg-10">

                            <ul className="row contact-list">

                                <li className="col-md-6">

                                    <div className="inner equal-hight">

                                        <div className="icon"><span className="icon-contact"></span></div>

                                        <h3>Contact Info</h3>

                                        <p>Comprehensive Support Services for Your Projects</p>

                                        <div className="call"><span className="icon-phone"></span> (+91) 99894 41153</div>

                                        <a href="mailto:info@protech.com" className="mail-to"><span className="icon-mail"></span>info@constructcoretech.com</a> </div>

                                </li>

                                {/* <li className="col-md-4">

                                    <div className="inner equal-hight">

                                        <div className="icon"><span className="icon-live-chat"></span></div>

                                        <h3>Live Chat</h3>

                                        <p>Lorem Ipsum is simply dummy text of the printing</p>

                                        <a href="#" className="live-chat"><span className="icon-chat-bubble"></span> Live Chat</a> </div>

                                </li> */}

                                <li className="col-md-6">

                                    <div className="inner equal-hight">

                                        <div className="icon"><span className="icon-support-ticket"></span></div>

                                        <h3>Support Tickets</h3>

                                        <p>Select support and provide your queries</p>

                                        <a href="#" className="live-chat support">Support</a> </div>

                                </li>

                            </ul>

                        </div>

                    </div>

                    <div className="row justify-content-center">

                        <div className="col-md-10 col-lg-8">

                            <div className="support-request">

                                <h2>Support Request</h2>

                                <p>Feel free to submit your query, and our team will carefully analyze your requirements to provide you with the best possible solution.</p>

                                <form action="#" method="get" className="support-form">

                                    <div className="row">

                                        <div className="col-md-6">

                                            <input name="Name" type="text" placeholder="Name" />

                                            <input name="Email" type="text" placeholder="Email" />

                                        </div>

                                        <div className="col-md-6">

                                            <textarea name="Mesage" placeholder="Mesage"></textarea>

                                        </div>

                                        <div className="col-md-12">

                                            <button className="submit-btn">Submit</button>

                                        </div>

                                    </div>

                                </form>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className="advices-outer padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10 col-lg-8">

                            <h2>Advice and answers from Our Team</h2>

                            <form action="#" method="get" className="search-outer d-flex">

                                <input name="Search" type="text" placeholder="Search" />

                                <button className="go-btn"><span className="icon-search"></span></button>

                            </form>

                        </div>

                    </div>

                    <ul className="row features-listing">

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><img src="images/info-ico.png" alt="" /></span>

                                <h3>Information about <span>ConstructCoreTech portal</span></h3>

                                <p>All-in-one solution for streamlined construction management. Experience advanced document management, efficient project tracking, real-time analytics, and more, tailored to the needs of the modern construction industry.</p>

                            </div>

                        </li>

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><img src="images/payment-ico.png" alt="" /></span>

                                <h3>ConstructCoreTech<span>Portal Payment</span></h3>

                                <p>Seamlessly manage your transactions, invoices, and financial processes with our user-friendly payment platform, designed to simplify your construction business operations.</p>

                            </div>

                        </li>

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><img src="images/paying-ico.png" alt="" /></span>

                                <h3>Paying with <span>ConstructCoreTech Portal</span></h3>

                                <p>Streamline your financial transactions, enjoy secure transactions, and stay in control of your construction project finances with our intuitive payment solution.</p>

                            </div>

                        </li>

                    </ul>

                </div>

            </section>

            {/* <section className="signup-outer gradient-bg padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10">

                            <ul className="clearfix">

                                <li> <span className="icon-men"></span>

                                    <h4>Signup for an <span>Account</span></h4>

                                </li>

                                <li> <span className="icon-chat"></span>

                                    <h4>Discuss with <span>our team</span></h4>

                                </li>

                                <li> <span className="icon-lap"></span>

                                    <h4>Receive a <span>good support</span></h4>

                                </li>

                            </ul>

                            <div className="signup-form">

                                <form action="#" method="get">

                                    <div className="email">

                                        <input name="email" type="text" placeholder="email" />

                                    </div>

                                    <div className="password">

                                        <input name="password" type="password" placeholder="password" />

                                    </div>

                                    <button className="signup-btn">Sign up Now</button>

                                </form>

                            </div>

                        </div>

                    </div>

                </div>

            </section> */}
        </div>
    )
}
