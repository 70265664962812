import React from 'react'

export default function PrivacyPolicy() {
    return (
        <>
            {/* <!-- ==============================================**Inner Banner**=================================================== --> */}

            <section className="inner-banner">

                <div className="container">

                    <div className="contents">

                        <h1>Privacy Policy</h1>

                        <p>Thank you for choosing [CONSTRUCTCORETECH] ("we", "our", or "us") a product of Unified Metaverse Arts Private Limited Company. This Privacy Policy outlines how we collect, use, disclose, and protect the personal information of our users ("you" or "users") in connection with your use of our software application and services.</p>

                    </div>

                </div>

            </section>



            {/* <!-- ==============================================**Privacy Policy**=================================================== --> */}

            <section className="privacy-policy padding-lg">

                <div className="container">

                    <h4>Information Collection</h4>

                    <p>We may collect the following types of personal information from users</p>

                    <ul className="quate-box">

                        <li>
                            <span>Email address</span><br />
                            <span>Phone number</span><br />
                            <span>Company name and designation</span><br />
                            <span>Project details and preferences</span><br />
                            <span>Payment information (if applicable)</span><br />
                            <span>Log and device information (IP address, browser type, etc.)</span><br />
                            <span>Cookies and usage data (as described in our Cookie Policy)</span>
                        </li>
                    </ul>

                    <hr />

                    <h4>Use of Information</h4>

                    <p>We may use the collected information for the following purposes.</p>

                    <ul className="quate-box">
                        <li>
                            <span>To create and manage your user account</span><br />
                            <span>To communicate with you regarding our services and updates</span><br />
                            <span>To provide customer support and respond to inquiries</span><br />
                            <span>To personalize your user experience</span><br />
                            <span>To process payments (if applicable)</span><br />
                            <span>To improve and optimize our software and services</span><br />
                            <span>To comply with legal obligations</span>
                        </li>
                    </ul>

                    <hr />

                    <h5>Data Storage</h5>

                    <p>Your personal information may be stored on our servers or on third-party cloud services. We take appropriate measures to safeguard your data and prevent unauthorized access.</p>

                    <hr />

                    <h5>Data Security</h5>

                    <p>We implement security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is completely secure, and we cannot guarantee its absolute security.</p>

                    <hr />

                    <h5>Cookies and Tracking</h5>

                    <p>We use cookies and similar tracking technologies to analyze user behavior, track website usage, and improve our services. You may control cookies through your browser settings.</p>

                    <hr />

                    <h5>Third-Party Sharing</h5>

                    <p>We may share your personal information with third-party service providers to perform tasks on our behalf, such as hosting, payment processing, and analytics. We ensure that these providers adhere to data protection standards.</p>

                    <hr />

                    <h5>User Choices</h5>

                    <p>You have the right to access, update, and delete your personal information from your user account. You may also contact us at [Contact Email/Phone] to exercise your rights or seek assistance.</p>

                    <hr />

                    <h5>Legal Compliance</h5>

                    <p>We comply with applicable data protection laws and regulations, including the Indian Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.</p>

                    <hr />

                    <h5>Updates to the Privacy Policy</h5>

                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website, and the date of the latest revision will be indicated at the top of the policy.</p>

                    <br />

                    <p>If you have any questions, concerns, or requests related to your privacy, please contact us.</p>

                </div>

            </section>

        </>
    )
}
