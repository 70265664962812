import React, { Component } from "react";
import { Modal, Form } from "react-bootstrap";
import shape from "../images/Shape.png";
import Accordion from "react-bootstrap/Accordion";
import "./modal.scss"


interface UsermanagementPayload {
  id?: any;
  name: string;
  account: string;
  development: string;
  project: string;
  role: string;
  category: string;
  group: string;
  status: string;
  document_management: string;
  project_management: string;
  planning: string;
  commercial: string;
}

interface usermanagementState {
  id: any;
  name: string;
  account: string;
  development: string;
  project: string;
  role: string;
  category: string;
  group: string;
  status: string;
  document_management: string;
  project_management: string;
  planning: string;
  commercial: string;
  development_data: any;
  projects_data: any;
}

interface insuranceProps {
  open: boolean;
  closeModal: Function;
  editData?: any;
  id?: any;
}

export default class UserManagementModal extends Component<
  insuranceProps,
  usermanagementState
> {
  state = {
    id: "",
    name: "",
    account: "",
    development: "",
    project: "",
    role: "",
    category: "",
    group: "",
    status: "",
    document_management: "",
    project_management: "",
    planning: "",
    commercial: "",
    development_data: [],
    projects_data: [],
  };

  getUserManagementPayload() {
    let userManagmentPayload: UsermanagementPayload = {
      name: this.state.name,
      account: this.state.account,
      development: this.state.development,
      project: this.state.project,
      role: this.state.role,
      category: this.state.category,
      group: this.state.group,
      status: this.state.status,
      document_management: this.state.document_management,
      project_management: this.state.project_management,
      planning: this.state.planning,
      commercial: this.state.commercial,
    };

    let payload: any = {
      attributes: {
        id: this.state.id,
        name: userManagmentPayload.name,
        account: userManagmentPayload.account,
        development: userManagmentPayload.development,
        project: userManagmentPayload.project,
        role: userManagmentPayload.role,
        category: userManagmentPayload.category,
        group: userManagmentPayload.group,
        status: userManagmentPayload.status,
        document_management: userManagmentPayload.document_management,
        project_management: userManagmentPayload.project_management,
        planning: userManagmentPayload.planning,
        commercial: userManagmentPayload.commercial,
      },
    };
    return payload;
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  handledevChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ development: e.target.value });
    this.setState((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    // this.fetchProjectsData();
  };

  handleSubmit(e: any) {
    e.preventDefault();
    console.log("save", this.getUserManagementPayload());

    this.getUserManagementPayload();
  }

  getDevelopmentPayload() {
    let payload: any = {
      attributes: {
        user_id: sessionStorage.getItem("user_id"),
        page: 1,
        limit: 20,
      },
    };
    return payload;
  }

  // fetchData = async () => {
  //   try {
  //     await api_service
  //       .post({
  //         endPoint: api_service.api_urls.FETCH_DEVELOPMENT,
  //         domain: "http://44.215.80.246:4000",
  //         payLoad: this.getDevelopmentPayload(),
  //       })
  //       ?.then((res) => {
  //         if (res.data.status === 200) {
  //           console.log(JSON.stringify(res.data));
  //           this.setState({ development_data: res.data.data });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // componentDidMount() {
  //   this.fetchData();
  //   this.fetchProjectsData();
  //   if (this.props.id !== "") {
  //     api_service
  //       .post({
  //         endPoint: api_service.api_urls.SINGLELETTERSDATA,
  //         domain: "http://44.215.80.246:4000",
  //         payLoad: { attributes: { id: this.props.id } },
  //       })
  //       ?.then((res) => {
  //         console.log(res.data.data);
  //         this.setState((p) => ({
  //           ...p,
  //           id: res.data.data.id,
  //         }));
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }

  //   console.log("hi");
  // }

  getProjectPayload() {
    let dev_id: any = this.state.development;

    let payload: any = {
      attributes: {
        development_id: parseInt(dev_id),
        page: 1,
        limit: 20,
      },
    };
    return payload;
  }

  // fetchProjectsData = async () => {
  //   try {

  //     console.log("this.state.development214", this.state.development)
  //     if(this.state.development !== ""){
  //       await api_service
  //       .post({
  //         endPoint: api_service.api_urls.FETCH_PROJECTS,
  //         domain: "http://44.215.80.246:4000",
  //         payLoad: this.getProjectPayload(),
  //       })
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           // console.log(res.data.data.rows);
  //           console.log("229------",res.data.data);
  //           this.setState({ projects_data: res.data.data });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     }

  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  render() {
    console.log(this.state);
    return (
      <Modal
        size="lg"
        show={this.props.open}
        onHide={() => this.props.closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>NEW MEMBER</Modal.Title>
        </Modal.Header>
        <Form
          className="commercial_form"
          onSubmit={(e:any) => this.handleSubmit(e)}
        >
          <Modal.Body>
            <Accordion
              className="commercial_accordian"
              defaultActiveKey={["0", "1", "2", "3"]}
              alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>General</Accordion.Header>
                <Accordion.Body>
                  <div className="row border-bottom pt-4">
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="">Name</Form.Label>
                      <Form.Control
                        type="text"
                        className=" "
                        required
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="">Account</Form.Label>
                      <Form.Control
                        type="text"
                        className=""
                        required
                        name="account"
                        value={this.state.account}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="label_style">
                        Development
                      </Form.Label>
                      <select
                        className="dropdownInput dropdownStyle"
                        name="development"
                        required
                        // onChange={(e: any) => this.handledevChange(e)}
                        onChange={(e: any) => this.handledevChange(e)}
                      >
                        <option>Select</option>
                        {this.state.development_data?.map(
                          (item: any, i: any) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="">Project</Form.Label>

                      <select
                        className="dropdownInput dropdownStyle"
                        name="project"
                        required
                        // onChange={(e: any) => this.handledevChange(e)}
                        onChange={(e: any) => this.handledevChange(e)}
                      >
                        <option>Select</option>
                        {this.state.projects_data?.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-6 col-12 input_div m-0 dropdownOption">
                      <Form.Label className="">Role</Form.Label>
                      <select
                        className="dropdownInput"
                        name="role"
                        value={this.state.role}
                        onChange={(e: any) => this.handleChange(e)}
                      >
                        <option value="sunny">Sunny</option>
                        <option value="cloudy">Cloudy</option>
                        <option value="foggy">Foggy</option>
                        <option value="rain">Rain</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="">
                        Category - Department
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className=""
                        required
                        name="category"
                        value={this.state.category}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 input_div m-0 dropdownOption">
                      <Form.Label className="">Group</Form.Label>
                      <select
                        className="dropdownInput "
                        name="group"
                        value={this.state.group}
                        onChange={(e: any) => this.handleChange(e)}
                      >
                        <option value="sunny">Sunny</option>
                        <option value="cloudy">Cloudy</option>
                        <option value="foggy">Foggy</option>
                        <option value="rain">Rain</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-12 input_div m-0  dropdownOption">
                      <Form.Label className="">Status</Form.Label>

                      <select
                        className="dropdownInput "
                        name="status"
                        value={this.state.status}
                        onChange={(e: any) => this.handleChange(e)}
                      >
                        <option value="sunny">Sunny</option>
                        <option value="cloudy">Cloudy</option>
                        <option value="foggy">Foggy</option>
                        <option value="rain">Rain</option>
                      </select>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Final Certificate Checklist</Accordion.Header>
                <Accordion.Body>
                  <div className="pt-4">
                    <div className="table-responsive checklist_table ">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Document Management</td>
                            <td>
                              <select
                                className=""
                                name="document_management"
                                value={this.state.document_management}
                                onChange={(e: any) => this.handleChange(e)}
                              >
                                <option value="sunny">Sunny</option>
                                <option value="cloudy">Cloudy</option>
                                <option value="foggy">Foggy</option>
                                <option value="rain">Rain</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Project Management</td>
                            <td>
                              <select
                                className=""
                                name="project_management"
                                value={this.state.project_management}
                                onChange={(e: any) => this.handleChange(e)}
                              >
                                <option value="sunny">Sunny</option>
                                <option value="cloudy">Cloudy</option>
                                <option value="foggy">Foggy</option>
                                <option value="rain">Rain</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Planning</td>
                            <td>
                              <select
                                className=""
                                name="planning"
                                value={this.state.planning}
                                onChange={(e: any) => this.handleChange(e)}
                              >
                                <option value="sunny">Sunny</option>
                                <option value="cloudy">Cloudy</option>
                                <option value="foggy">Foggy</option>
                                <option value="rain">Rain</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Commercial</td>
                            <td>
                              <select
                                className=""
                                name="commercial"
                                value={this.state.commercial}
                                onChange={(e: any) => this.handleChange(e)}
                              >
                                <option value="sunny">Sunny</option>
                                <option value="cloudy">Cloudy</option>
                                <option value="foggy">Foggy</option>
                                <option value="rain">Rain</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="commercial_footer d-flex flex-row justify-content-between">
              <button
                className="modal_cancel_btn"
                onClick={() => this.props.closeModal()}
              >
                CANCEL
              </button>
              <button className="modal_save_btn" type="submit">
                SUBMIT
              </button>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}
