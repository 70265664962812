import React from 'react'

export default function Career() {
    return (
        <div>

            <section className="inner-banner career-banner" style={{ minHeight: "300px" }}>

                <div className="container">

                    <div className="contents">

                        <h1>Build Your Future</h1>

                        <p>Explore Career Opportunities at the Forefront of Construction Innovation with ConstructCoreTech. <br />ConstructCoreTech invites you to embark on a transformative journey in the realm of construction technology.</p>

                        <a href="#current-openings" style={{ margin: "19px auto" }} className="get-started scroll-link">Opening Positions</a> </div>

                </div>

            </section>

            <section className="success-everyday">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-lg-10">

                            <h2>We ship Success everyday</h2>

                            <p>Our career opportunities aren't just about jobs; they're about shaping the future. Join our dynamic team of innovators, where every role contributes to revolutionizing how the world builds. Whether you're a seasoned professional or just starting out, we offer a supportive environment where your ideas are valued and your growth is nurtured. Take the next step in your career with ConstructCoreTech and be part of something extraordinary.</p>

                        </div>

                    </div>

                </div>

            </section>

            <section className="career-our-values-sec">

                <div className="container">

                    <div className="row">

                        <div className="col-md-8">
                            <h3>Our Values</h3>
                            <ul className="row our-values">
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/EmpowerIndividualGrowth.png"></img>
                                    </div>
                                    <div className="text-area">
                                        <h6>Empower Individual Growth</h6>
                                        <p>We foster an environment that encourages continuous learning and personal development, allowing each team member to grow both personally and professionally.</p>
                                    </div>
                                </li>
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/Result Oriented Approach 1.png"></img>
                                    </div>
                                    <div className="text-area">
                                        <h6>Results-Oriented Approach</h6>
                                        <p>Our focus is on achieving meaningful outcomes and exceeding expectations, always striving for excellence in everything we do</p>
                                    </div>
                                </li>
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/Simplicity and Innovation 1.png"></img>
                                    </div>
                                    <div className="text-area">
                                        <h6>Simplicity and Innovation</h6>
                                        <p>We embrace simplicity and innovation in our solutions, empowering our team to create user-friendly products that make a significant impact in the construction industry.</p>
                                    </div>
                                </li>
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/Health and Wellbeing 1.png"></img>

                                    </div>
                                    <div className="text-area">
                                        <h6>Health and Well-being</h6>
                                        <p>We prioritize the well-being of our team, promoting a healthy work-life balance and providing a supportive and inclusive work environment.</p>
                                    </div>
                                </li>
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/Customer Centric Mindset 1.png"></img>
                                    </div>
                                    <div className="text-area">
                                        <h6>Customer-Centric Mindset</h6>
                                        <p>Our team is dedicated to understanding and addressing the unique needs of our clients, delivering solutions that truly make a difference in their projects.</p>
                                    </div>
                                </li>
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/Collaborative Spirit.png"></img>
                                    </div>
                                    <div className="text-area">
                                        <h6>Collaborative Spirit</h6>
                                        <p>We believe in the power of teamwork and collaboration, fostering an environment where every team member's input is valued and respected.</p>
                                    </div>
                                </li>
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/Integrity and Accountability 1.png"></img>
                                    </div>
                                    <div className="text-area">
                                        <h6>Integrity and Accountability</h6>
                                        <p>We uphold the highest standards of integrity and take accountability for our actions, ensuring transparency and trust with our team and clients.</p>
                                    </div>
                                </li>
                                <li className="col-md-6">
                                    <div className="icon">
                                        <img style={{ height: "50px" }} src="images/Celebrating Success Together 1.png"></img>
                                    </div>
                                    <div className="text-area">
                                        <h6>Celebrating Success Together</h6>
                                        <p>We celebrate individual and team achievements, recognizing and appreciating the hard work and dedication that drives our success.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <div className="our-perks">
                                <h3>Our Perks</h3>
                                <ul className="icon-tik-list">
                                    <li>
                                        <h6>Health Insurance</h6>
                                        <p>Comprehensive health insurance for you and your parents.</p>
                                    </li>
                                    <li>
                                        <h6>ESOPs (Employee Stock OptionPlans)</h6>
                                        <p>Gain a stake in our success through our employee stock option program. </p>
                                    </li>
                                    <li>
                                        <h6>Food Allowance</h6>
                                        <p>Boost your savings with our food allowances.</p>
                                    </li>
                                    <li>
                                        <h6>Business Expenses Reimbursements</h6>
                                        <p>Easily claim reimbursements for business-related expenses to stay focused on your work. </p>
                                    </li>
                                    <li>
                                        <h6>Health Reimbursements</h6>
                                        <p>Get reimbursed for your gym, yoga, and other wellness expenses to maintain a healthy lifestyle. </p>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <section className="wht-can-we-do-outer padding-lg" id="current-openings">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 content-area">
                            <h2>Build Your Future with Us</h2>
                            <p>Apply Now! Explore exciting career opportunities with us and become part of our innovative team. With competitive benefits and a supportive work culture, we are committed to helping you succeed.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="career-info-panel equal-hight">
                                <h3>Front-end Developer</h3>
                                <p>Background of SaaS preferred</p>
                                <ul>
                                    <li>React</li>
                                    <li>HTML5</li>
                                    <li>CSS3</li>
                                    <li>3+ Years Exp</li>
                                </ul>
                                <a className="btn apply-now" href="/apply-job?role=frontenddeveloper">Apply Now</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="career-info-panel equal-hight">
                                <h3>UI/UX Designer</h3>
                                <p>Strong background of wireframes, prototypes, and mockups</p>
                                <ul>
                                    <li>Figma</li>
                                    <li>Zeplin</li>
                                    <li>Illustrator</li>
                                    <li>3+ Years Exp</li>
                                </ul>
                                <a className="btn apply-now" href="/apply-job?role=uiuxdesigner">Apply Now</a> </div>
                        </div>
                        <div className="col-md-4">
                            <div className="career-info-panel equal-hight">
                                <h3>Backend Developer</h3>
                                <p>Preferred web and mobile applications background.</p>
                                <ul>
                                    <li>Node Js</li>
                                    <li>MySQL</li>
                                    <li>Wordpress</li>
                                    <li>3+ Years Exp</li>
                                </ul>
                                <a className="btn apply-now" href="/apply-job?role=backenddeveloper">Apply Now</a> </div>
                        </div>
                        <div className="col-md-4">
                            <div className="career-info-panel equal-hight">
                                <h3>Content Writer</h3>
                                <p>Create informative and engaging content</p>
                                <ul>
                                    <li>Journalism</li>
                                    <li>Communication</li>
                                    <li>Writing Skill</li>
                                    <li>3+ Years Exp</li>
                                </ul>
                                <a className="btn apply-now" href="/apply-job?role=contentwriter">Apply Now</a> </div>
                        </div>
                        <div className="col-md-4">
                            <div className="career-info-panel equal-hight">
                                <h3>Full Stack Developer</h3>
                                <p>Development and maintenance of Product</p>
                                <ul>
                                    <li>Microservices</li>
                                    <li>Build & Deploy</li>
                                    <li>Web Design</li>
                                    <li>5+ Years Exp</li>
                                </ul>
                                <a className="btn apply-now" href="/apply-job?role=fullstackdeveloper">Apply Now</a> </div>
                        </div>
                        <div className="col-md-4">
                            <div className="career-info-panel equal-hight">
                                <h3>Software Tester</h3>
                                <p>Qa Qc Implementation</p>
                                <ul>
                                    <li>Selenium</li>
                                    <li>Test Cases</li>
                                    <li>Bug Reports</li>
                                    <li>3+ Years Exp</li>
                                </ul>
                                <a className="btn apply-now" href="/apply-job?role=softwaretester">Apply Now</a> </div>
                        </div>
                        <div className="col-md-4">
                            <div className="career-info-panel equal-hight">
                                <h3>DevOps Engineer</h3>
                                <p>Responsible for building and setting up new development tools</p>
                                <ul>
                                    <li>Build & Deploy</li>
                                    <li>Aws/Cloud</li>
                                    <li>CI/CD Setup</li>
                                    <li>5+ Years Exp</li>
                                </ul>
                                <a className="btn apply-now" href="/apply-job?role=devopsengineer">Apply Now</a> </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="signup-outer gradient-bg padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10">

                            <ul className="clearfix">

                                <li> <span className="icon-men"></span>

                                    <h4>Signup for an <span>Account</span></h4>

                                </li>

                                <li> <span className="icon-chat"></span>

                                    <h4>Discuss with <span>our team</span></h4>

                                </li>

                                <li> <span className="icon-lap"></span>

                                    <h4>Receive a <span>good support</span></h4>

                                </li>

                            </ul>

                            <div className="signup-form">

                                <form action="#" method="get">

                                    <div className="email">

                                        <input name="email" type="text" placeholder="email" />

                                    </div>

                                    <div className="password">

                                        <input name="password" type="password" placeholder="password" />

                                    </div>

                                    <button className="signup-btn">Sign up Now</button>

                                </form>

                            </div>

                        </div>

                    </div>

                </div>

            </section> */}
        </div>
    )
}
