import React from 'react'

export default function Footer() {
    return (
        <div>
            <footer className="footer dark-bg">

                <div className="top">

                    <div className="container">

                        <div className="row">

                            <div className="col-md-4 col-lg-3 mob-acco">

                                <div className="quick-links">

                                    <h4>Quick Links</h4>

                                    <ul>

                                        <li><a href="/">Home</a></li>

                                        {/* <li><a href="blog-grid.html">Blog</a></li> */}

                                        <li><a href="/about">About Us</a></li>

                                        {/* <li><a href="how-it-works.html">How it Works</a></li> */}

                                        <li><a href="/features">Features</a></li>

                                        <li><a href="/faq">FAQ</a></li>

                                        {/* <li><a href="portfolio.html">Portfolio</a></li> */}

                                        <li><a href="/career">Career</a></li>

                                        <li><a href="/pricing">Pricing</a></li>

                                        <li><a href="/support">Support</a></li>

                                        <li><a href="/contact">Contact Us</a></li>

                                        <li><a href="/privacy-policy">Privacy policy</a></li>

                                    </ul>

                                </div>

                                <div className="connect-outer">

                                    <h4>Connect with Us</h4>

                                    <ul className="connect-us">

                                        <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>

                                        {/* <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> */}

                                        <li><a href="https://www.linkedin.com/company/unified-metaverse-arts-private-limited/?viewAsMember=true"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>

                                        {/* <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li> */}

                                        <li><a href="https://www.youtube.com/@constructcoretech"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>

                                        <li><a href="https://www.instagram.com/constructcoretech?igsh=ZXA2OWpqbWQyMGp5&utm_source=qr"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>

                                    </ul>

                                </div>

                            </div>

                            {/* <div className="col-md-4 col-lg-4 mob-acco">

                                <div className="recent-post">

                                    <h4>Recent Post</h4>

                                    <ul className="list-unstyled">

                                        <li className="media">

                                            <figure className="d-flex"><img src="images/rp-thumb1.jpg" className="img-fluid" alt=""/></figure>

                                            <div className="media-body">

                                                <h5>A galley of type and scrambled it to make a type.</h5>

                                                <p><span>28 Oct</span> 2017</p>

                                            </div>

                                        </li>

                                        <li className="media">

                                            <figure className="d-flex"><img src="images/rp-thumb2.jpg" className="img-fluid" alt=""/></figure>

                                            <div className="media-body">

                                                <h5>A galley of type and scrambled it to make a type.</h5>

                                                <p><span>26 Sep</span> 2017</p>

                                            </div>

                                        </li>

                                        <li className="media">

                                            <figure className="d-flex"><img src="images/rp-thumb3.jpg" className="img-fluid" alt=""/></figure>

                                            <div className="media-body">

                                                <h5>A galley of type and scrambled it to make a type.</h5>

                                                <p><span>09 Aug</span> 2017</p>

                                            </div>

                                        </li>

                                    </ul>

                                </div>

                            </div>
                            <div className="col-md-4 col-lg-5">

                                <div className="subscribe">

                                    <h4>Subscribe  with Us</h4>

                                    <p className="hidden-xs">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                                    <div className="input-outer clearfix">

                                        <div id="mc_embed_signup">

                                            <form action="//protechtheme.us16.list-manage.com/subscribe/post?u=cd5f66d2922f9e808f57e7d42&amp;id=ec6767feee" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>

                                                <div id="mc_embed_signup_scroll">

                                                    <input type="email" value="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required/>

                                                        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">

                                                            <input type="text" name="b_cd5f66d2922f9e808f57e7d42_ec6767feee" tabIndex={-1} value=""/>

                                                        </div>

                                                        <div className="clear">

                                                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>

                                                        </div>

                                                </div>

                                            </form>

                                        </div>

                                    </div>

                                </div>

                                <div className="tweet clearfix"> <span className="icon"><i className="fa fa-twitter" aria-hidden="true"></i></span>

                                    <div className="right-cnt">

                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry' sindustry.</p>

                                        <div className="sourse">ProtechTheme <span>@protechtheme,</span>

                                            <ul>

                                                <li><a href="#"><i className="fa fa-reply" aria-hidden="true"></i></a></li>

                                                <li><a href="#"><i className="fa fa-retweet" aria-hidden="true"></i></a></li>

                                                <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>

                                            </ul>

                                        </div>

                                    </div>

                                </div>

                            </div> */}

                        </div>

                    </div>

                </div>

                <div className="bottom">

                    <div className="container"> Copyright © 2022 Unified Metaverse Arts Private Limited. All Rights Reserved. </div>

                </div>

            </footer>
            <a href="#" className="scroll-top"><i className="fa fa-angle-up" aria-hidden="true"></i></a> 
        </div>
    )
}
