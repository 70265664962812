export const options = [
    {
        id: 1,
        questions: "What is ConstructCoreTech's construction management software?",
        text: "ConstructCoreTech offers an advanced construction management software that helps streamline project processes, improve collaboration, and enhance overall project efficiency."
    },
    {
        id: 1,
        questions: "Can I track project progress using the software?",
        text: " Yes, the software allows you to monitor and track project progress in real - time, providing a comprehensive overview of tasks, milestones, and deadlines."
    },
    {
        id: 1,
        questions: "How does ConstructCoreTech handle project management tasks ?",
        text: "Our software comes with robust project management tools that assist in task assignment, progress tracking, issue resolution, and effective communication among team members."
    },
    {
        id: 1,
        questions: "Is planning and scheduling integrated into the software ?",
        text: "Absolutely! ConstructCoreTech's software includes powerful planning and scheduling features to help you create detailed project timelines and manage resources efficiently (this feature is in development stage)."
    },
    {
        id: 1,
        questions: "How does the software support commercial management ?",
        text: "ConstructCoreTech's software offers comprehensive commercial management capabilities, including contracts, payment processing, invoicing, and final account management."
    },
    {
        id: 1,
        questions: "Can I customize the software to match my project's unique requirements?",
        text: "Yes, our software is designed to be flexible, and we offer customization options to align with your specific project needs."

    },
    {
        id: 1,
        questions: "Is ConstructCoreTech's software user-friendly?",
        text: "Definitely! We prioritize user - friendliness, ensuring that even non - technical users can easily navigate and operate the software."
    },
    {
        id: 1,
        questions: "Is data security ensured when using the software?",
        text: "Yes, data security is of utmost importance to us.Our software follows industry - standard protocols to safeguard your sensitive information."
    },
    {
        id: 1,
        questions: "How does the software support collaboration among team members ?",
        text: " ConstructCoreTech's software facilitates seamless communication and collaboration, allowing team members to share documents, updates, and feedback in real-time."
    },
    {
        id: 1,
        questions: "Can I generate insightful reports using the software?",
        text: "Absolutely! Our software provides in -depth reporting capabilities, offering valuable insights and analytics to make informed decisions."
    },
    {
        id: 1,
        questions: "Is the software cloud - based ?",
        text: "Yes, our software is cloud - based, enabling access from anywhere with an internet connection."
    },
    {
        id: 1,
        questions: "Can I integrate the software with other applications ?",
        text: "No."
    },
    {
        id: 1,
        questions: "Is the software scalable for large construction projects ?",
        text: "Yes, ConstructCoreTech's software is scalable and can efficiently handle projects of varying sizes and complexities."
    },
    {
        id: 1,
        questions: "How does the software handle change orders and variations ?",
        text: "Our software includes features to manage change orders and variations efficiently, ensuring clear communication and documentation throughout the process."
    },
    {
        id: 1,
        questions: "Does the software offer a mobile app for on - site use ?",
        text: "No, it is in development stage, currently supported with webapp or browser."
    },

    {
        id: 1,
        questions: "Is ConstructCoreTech's software compliant with industry standards?",
        text: "Yes, our software adheres to industry standards and best practices to provide reliable and compliant solutions."
    },
    {
        id: 1,
        questions: "Can I generate customizable dashboards to visualize project data ?",
        text: "No, all the dashboards are generated from the pre - loaded tools."
    },
    {
        id: 1,
        questions: "Is training provided for users to optimize software usage ?",
        text: "Yes, we offer comprehensive training and customer support to ensure users can fully utilize the software's capabilities."
    },
    {
        id: 1,
        questions: "How can I stay updated on new software features and updates ?",
        text: "Users receive regular updates and notifications about new features and improvements to keep them informed."
    },
    {
        id: 2,
        questions: "How does the software help in document management?",
        text: "The software enables efficient storage, retrieval, and sharing of project documents, drawings, and submittals, ensuring easy access and version control."
    },
    {
        id: 2,
        questions: "Can I generate invoices and track payments using the software?",
        text: "Yes, the software offers invoicing features, allowing you to generate invoices, track payments, and manage financial transactions."
    },
    {
        id: 2,
        questions: "Can I grant different access levels to team members?",
        text: "Absolutely! Our software supports role-based access, enabling you to assign different permission levels to team members based on their responsibilities."
    },
    // {
    //     id: 2,
    //     questions: "Does the software offer offline capabilities?",
    //     text: "Yes, our software includes offline capabilities to ensure uninterrupted access to critical project data, even in areas with limited internet connectivity."
    // },
    {
        id: 2,
        questions: "Can I export data from the software for external use?",
        text: "Yes, our software allows you to export data and reports in various formats for external use and analysis."
    },
    {
        id: 2,
        questions: "Is ConstructCoreTech's software customizable for specific industry needs?",
        text: "Yes, we offer customization options to align the software with specific industry requirements."
    },
    {
        id: 2,
        questions: "Is there a trial or demo version available for testing the software?",
        text: "Yes, we provide a trial or demo version of the software for interested clients to explore its features and functionalities."
    },
    {
        id: 3,
        questions: "How does ConstructCoreTech ensure data security and privacy?",
        text: " We employ state-of-the-art security measures, encryption protocols, and secure hosting to protect user data and ensure utmost privacy."
    },
    {
        id: 3,
        questions: "Does the software comply with data protection regulations?",
        text: " Yes, ConstructCoreTech's software complies with data protection regulations and privacy laws to safeguard user information."
    },
    {
        id: 4,
        questions: "What features are included in the Basic plan?",
        text: "The Basic plan includes essential features to manage your construction projects efficiently, such as document management, project tracking, and basic reporting."
    },
    {
        id: 4,
        questions: "What are the benefits of upgrading to the Premium plan?",
        text: "Upgrading to the Premium plan unlocks advanced functionalities, including enhanced reporting, resource planning, and collaboration tools for seamless project management."
    },
    {
        id: 4,
        questions: "Is the Enterprise plan suitable for large-scale construction firms?",
        text: "Yes, the Enterprise plan is designed to cater to the needs of large construction companies, offering extensive project management capabilities, customizations, and dedicated support."
    },
    {
        id: 4,
        questions: "Can I switch between plans if my requirements change?",
        text: "Absolutely! You have the flexibility to upgrade or downgrade your plan based on your evolving project needs and business requirements."
    },
    {
        id: 4,
        questions: "Are there any discounts for long-term commitments?",
        text: "Yes, we offer attractive discounts for clients who opt for long-term commitments and annual subscriptions."
    },
    {
        id: 4,
        questions: "What payment methods are accepted for the subscription?",
        text: "We accept various payment methods, including credit cards, bank transfers, and other secure online payment options."
    },
    {
        id: 4,
        questions: "Is there a trial period for the plans before committing to a subscription?",
        text: "Yes, we provide a trial period for all plans, allowing you to explore the software's features and functionalities before making a decision."
    },
    {
        id: 4,
        questions: "Can I customize my plan to add specific features?",
        text: "Yes, we understand that each construction project has unique requirements. We offer the flexibility to customize plans with additional features based on your preferences."
    },
    {
        id: 4,
        questions: "Are there any limitations on the number of users in each plan?",
        text: "Our plans accommodate varying user requirements, and you can choose a plan that aligns with the number of users in your construction team."
    },
    {
        id: 4,
        questions: "What level of customer support is offered for each plan?",
        text: "All our plans come with dedicated customer support to ensure a seamless experience. The level of support may vary based on the plan chosen."
    },
    {
        id: 4,
        questions: "Can I request a quote for a customized plan that suits my company's specific needs?",
        text: "Absolutely! If none of our existing plans perfectly align with your requirements, you can request a personalized quote for a tailor-made plan that meets your exact needs."
    },
    {
        id: 4,
        questions: "Is there an option for on-premises deployment for the Enterprise plan?",
        text: "Yes, our Enterprise plan provides the option for on-premises deployment for companies that prefer to host the software internally and the companies should abide by the ConstructCoreTech’s software terms and conditions."
    },
    {
        id: 4,
        questions: "Is there a cancellation policy for the subscription plans?",
        text: "We have a flexible cancellation policy that allows you to cancel your subscription at any time, with proper notice."
    },
    {
        id: 4,
        questions: "Are software updates included in the subscription plans?",
        text: "Yes, all subscription plans include regular software updates and enhancements to ensure you have access to the latest features and improvements."
    },
    {
        id: 4,
        questions: "Can I add or remove users from the plan during the subscription period?",
        text: "Yes, you can easily add or remove users from your plan as your team size changes, providing scalability and cost-effectiveness."
    },
    {
        id: 4,
        questions: "Does each plan have a maximum project capacity or storage limit?",
        text: "Our plans are designed to accommodate varying project sizes and data storage needs, ensuring sufficient capacity for your projects."
    },
    {
        id: 4,
        questions: "Can I get a demo of the software before choosing a plan?",
        text: "Yes, we offer live demos of the software to help you understand its capabilities and features, allowing you to make an informed decision."
    },
    {
        id: 4,
        questions: "Are there any additional fees for customer support or training services?",
        text: "Our plans include customer support and training services as part of the subscription, with no additional hidden fees."
    },
    {
        id: 4,
        questions: "Can I upgrade my plan at any time during the subscription period?",
        text: "Yes, you can upgrade your plan at any time to access more advanced features and functionalities."
    },
    {
        id: 4,
        questions: "What are the pricing options and plans available for the software?",
        text: "Please contact our sales team for detailed information on pricing and plans tailored to your specific needs. We offer flexible options to accommodate different project sizes and budgets."
    }
]    
