import React from 'react';
import './StrikeThroughAmount.css'; 

interface StrikeThroughAmountProps {
  amount: any; 
}

const StrikeThroughAmount: React.FC<StrikeThroughAmountProps> = ({ amount }) => {
  return (
    <div className="strike-through">
      {amount} INR
    </div>
  );
};

export default StrikeThroughAmount;