import { Component } from "react";
import { NavLink } from "react-router-dom";
import "../sideBar/sidebar.css"

interface SidebarState {
  open: boolean;
  isExpanded: boolean;
}

interface propsType {
  item: any;
  siderBarClick?: boolean;
  sidebarOpenClose:Function
}

export default class SidebarItem extends Component<propsType, SidebarState> {
  state = {
    open: false,
    isExpanded: false,
  };

  render() {
    if (this.props.item.child) {
      return (
        <div className={this.state.open ? "sidebar-item open" : "sidebar-item"}>
          <div
          className={this.state.open ? "sidebar-text open" : "sidebar-text"}
            onClick={() => this.setState((prev: any) => ({ open: !prev.open }))}
          >
            <span className="arrange__sidebar__option__withchild">
              <div>
                {this.props.item.icon && (
                  <img
                    className={
                      this.props.siderBarClick
                        ? "toggle_sidebar_logo"
                        : "sidebar_logo"
                    }
                    src={this.props.item.icon}
                  />
                )}
                {this.props.siderBarClick ? null : (
                  <>
                    <p className="sidebar_option_text">
                      {this.props.item.text}
                    </p>
                  </>
                )}
              </div>
              {this.props.siderBarClick ? null : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="toggle-btn"
                  width="20px"
                >
                  <path
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              )}
            </span>
          </div>
          <div className="sidebar-content">
            {this.props.item.child.map((child: any, index: number) => (
              <SidebarItem
                key={index}
                item={child}
                siderBarClick={this.props.siderBarClick}
                sidebarOpenClose={this.props.sidebarOpenClose}
              />
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <NavLink to={this.props.item.path || "#"} className="sidebar-item">
            <div className="arrang_sidebar_position" onClick={()=>(this.props.sidebarOpenClose())}>
            {this.props.item.icon && (
              <img
                className={
                  this.props.siderBarClick
                    ? "toggle_sidebar_logo"
                    : "sidebar_logo"
                }
                src={this.props.item.icon}
                />
            )}
            {this.props.siderBarClick ? null : (
              <p className="sidebar_option_text">{this.props.item.text}</p>
            )}
            </div>
          </NavLink>
        </div>
      );
    }
  }
}