import React, { useEffect } from 'react'

export default function Header() {
    
    return (
        <>
            {/* <div id="loader">

                <div id="element">

                    <div className="circ-one"></div>

                    <div className="circ-two"></div>

                </div>

            </div> */}
            <header className="opt5">

                <nav className="navbar navbar-expand-lg navbar-light">

                    <div className="container">
                        <a className="navbar-brand" href="/"><img src="images/logo.png" className="img-fluid logo1" alt="" /><img src="images/logo2.png" className="img-fluid logo2" alt="" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                            <ul className="navbar-nav mr-auto">
                                {/* <br /><br /><br /> */}
                                <li className="nav-item dropdown">
                                    <br />
                                    <a className="nav-link" style={{ padding: "0 20px" }} href="/">Home</a>
                                </li>
                                <br />
                                <li className="nav-item dropdown">
                                    <br />
                                    <a className="nav-link" style={{ padding: "0 20px" }} href="/about">About</a>
                                </li>
                                <br />
                                <li className="nav-item dropdown">
                                    <br />
                                    <a className="nav-link" style={{ padding: "0 20px" }} href="/pricing">Pricing</a>
                                </li>
                                <br />
                                <li className="nav-item dropdown">
                                    <br />
                                    <a className="nav-link" style={{ padding: "0 20px" }} href="/features">Features</a>
                                </li>
                                <br />
                                <li className="nav-item dropdown">
                                    <br />
                                    <a className="nav-link" style={{ padding: "0 20px" }} href="/career">Career</a>
                                </li>
                                <br />
                                <li className="nav-item dropdown">
                                    <br />
                                    <a className="nav-link" style={{ padding: "0 20px" }} href="/support">Support</a>
                                </li>
                            </ul>
                            <ul className="navbar-right d-flex">
                                {/* <li><a href="register.html">Signup</a></li> */}
                                {/* <li><button onClick={() => this.props.history('/login')}>Login</button></li> */}
                            </ul>
                        </div>
                    </div>

                </nav>

            </header>
        </>
    )
}
