import React, { Component } from "react";
import addIcon from "../../assets/images/Add.svg";
import editIcon from "../../assets/images/Edit.svg";
import deleteIcon from "../../assets/images/Delete.svg";
import shareIcon from "../../assets/images/Share.svg";
import printIcon from "../../assets/images/Print.svg";
import searchIcon from "../../assets/images/Search.svg";
import filter from "../../assets/images/Filter.svg";
import "./userManagment.scss";
import UserManagementModal from "./UserManagementModal";
import { api_service } from "../../api/api_service";
import moment from "moment";
import _ from "../../utils/lodash";

interface bondsState {
  dbdata: Array<any>;
  checkBox: Array<any>;
  allChecked: Boolean;
  isAddButtonClicked: boolean;
  isEdit: Boolean;
  editData: any;
  document_management: any;
  project_management: any;
  planning: any;
  commercial: any;
}

export default class UserManagment extends Component<{}, bondsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      dbdata: [],
      checkBox: [],
      allChecked: false,
      isAddButtonClicked: false,
      isEdit: false,
      editData: "",
      document_management: "",
      project_management: "",
      planning: "",
      commercial: "",
    };
  }

  handleAllselect = (e: any) => {
    if (e.target.checked) {
      this.setState((prev) => ({ allChecked: true }));
      let f = this.state.dbdata.map((d: any) => ({ ...d, checked: true }));
      this.setState((prev) => ({ dbdata: f }));
    } else {
      this.setState({ allChecked: false });
      let f = this.state.dbdata.map((d: any) => ({ ...d, checked: false }));
      this.setState((prev) => ({ dbdata: f }));
    }
  };

  handleSelectEachRow = (e: any, row: any) => {
    if (e.target.checked) {
      let filtered = this.state.dbdata.map((f: any) => {
        if (f.id === row.id) {
          let d = { ...f, checked: e.target.checked };
          return d;
        } else {
          return f;
        }
      });
      this.setState({
        allChecked:
          this.state.dbdata.length ===
          this.state.dbdata.filter((r: any) => r.checked === true).length,
      });
      this.setState({ dbdata: filtered });
    } else {
      let filtered = this.state.dbdata.map((f: any) => {
        if (f.id === row.id) {
          let d = { ...f, checked: e.target.checked };
          return d;
        } else {
          return f;
        }
      });
      this.setState({ dbdata: filtered });
    }
  };

  checkBoxHandlar(e: any) {
    if (e.target.checked) {
      this.setState((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else {
      this.setState({
        checkBox: this.state.checkBox.filter((r) => r !== e.target.value),
      });
    }
  }

  closeModal() {
    this.setState((p) => ({ isAddButtonClicked: false, editData: "" }));
  }

  getPayload() {
    let payload: any = {
      attributes: {
        // client_id: sessionStorage.getItem("user_id"),
        fetch: 1,
        page: 1,
        limit: 20,
      },
    };
    return payload;
  }

  fetchData = async () => {
    try {
      await api_service
        .post({
          endPoint: api_service.api_urls.Search_CLIENT_USERS,
          domain: "http://44.215.80.246:4001",
          payLoad: this.getPayload(),
        })
        .then((res: any) => {
          if (res.data.status === 200) {
            // console.log(res.data.data.rows);
            console.log(res.data.data);
            let dataArray: any = [];
            let dataobj: any = {};
            res.data.data.forEach(
              (element: any) => (
                (dataobj = { ...element }),
                (dataobj.created_date_time = moment(
                  element.created_date_time
                ).format("DD/MM/YY")),
                (dataobj.updatedAt = moment(element.updatedAt).format(
                  "DD/MMM/YY"
                )),
                dataArray.push(dataobj)
              )
            );

            let dataArray1: any = [];
            // let dataobj1: any = {};
            _.each(dataArray, (eachobj: any) => {

              if(eachobj.Documents == undefined){
                eachobj.Documents= "No Access";
              }


              if(eachobj.Projects == undefined){
                eachobj.Projects= "No Access";
              } 
              if(eachobj.Scheduling == undefined){
                eachobj.Scheduling= "No Access";
              } 
              
              if(eachobj.Planning == undefined){
                eachobj.Planning= "No Access";
              } 
              
              if(eachobj.Commercial == undefined){
                eachobj.Commercial= "No Access";
              }

              if(eachobj.Subscription !== undefined && eachobj.Subscription.length >0){
                let plandata = eachobj.Subscription[0].Plan.PlanFeatures;
                _.each(plandata, (erp: any) => {

                  if(erp.feature_id !== undefined){
                    
                    if(erp.feature_id ==1){
                      eachobj.Documents= "Enabled";
                    }

                    if(erp.feature_id ==2){
                      eachobj.Projects="Enabled";
                    }  
                    if(erp.feature_id ==3){
                      eachobj.Scheduling="Enabled";
                    }  
                    if(erp.feature_id ==4){
                      eachobj.Planning="Enabled";
                    } 
                     if(erp.feature_id ==5){
                      eachobj.Commercial="Enabled";
                    }

                  }
                })

                dataArray1.push(eachobj);

              }
              // if (eachobj.UserRolePermissions.length > 0) {
              //   _.each(eachobj.UserRolePermissions, (erp: any) => {
              //     if (
              //       erp.ModulePermissions !== undefined &&
              //       erp.ModulePermissions.Modules !== undefined &&
              //       erp.ModulePermissions.Permissions
              //     ) {
              //       if (erp.ModulePermissions.Modules.id === 4) {
              //         eachobj.document_management =
              //           erp.ModulePermissions.Permissions.name;
              //       }
              //       if (erp.ModulePermissions.Modules.id === 3) {
              //         eachobj.project_management =
              //           erp.ModulePermissions.Permissions.name;
              //       }
              //       if (erp.ModulePermissions.Modules.id === 2) {
              //         eachobj.commercial =
              //           erp.ModulePermissions.Permissions.name;
              //       }
              //       if (erp.ModulePermissions.Modules.id === 1) {
              //         eachobj.planning = erp.ModulePermissions.Permissions.name;
              //       }
              //     }
              //   });
              // }

            
            });

            this.setState({ dbdata: dataArray1 });
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } catch (err) {
      // console.log(err);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    console.log(this.state.dbdata)
    const disableButton =
      this.state?.dbdata.filter((e: any) => e.checked === true).length > 0
        ? false
        : true;
    return (
      <div className="dashboard_table">
        <div className="header_section">
          <div className="title">User Management</div>
          <div className="options_section">
            <div className="input-group search_bar">
              <span className="input-group-text">
                <img src={searchIcon}></img>
              </span>
              <input
                className="form-control"
                type="text"
                placeholder="Search Projects..."
              />
            </div>
            <button
              className=""
              onClick={() =>
                this.setState((p) => ({ isAddButtonClicked: true }))
              }
            >
              <img src={addIcon}></img>
            </button>
            <div className="nav-item dropdown">
              <button
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="filter_btn"
              >
                <img
                  //   onClick={() => this.setState((p) => ({ click: !p.click }))}
                  src={filter}
                  alt="..."
                  className="dropdown-toggle"
                />
              </button>
              <div
                className="dropdown-menu dropdown-menu-end animate slideIn"
                aria-labelledby="navbarDropdown"
              >
                <a className="dropdown-item" href="#">
                  All
                </a>
                <a className="dropdown-item" href="#">
                  Admin
                </a>
                <a className="dropdown-item" href="#">
                  Teams
                </a>
              </div>
            </div>
            <button className="" disabled={disableButton}>
              <img src={shareIcon}></img>
            </button>
            <button className="" disabled={disableButton}>
              <img src={printIcon}></img>
            </button>
          </div>
        </div>
        <div className="table-responsive table_section">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div className="checkbox_div">
                    <input
                      id="checkbox"
                      className="checkbox-custom"
                      name="checkbox"
                      type="checkbox"
                      checked={
                        !this.state.dbdata?.some(
                          (rows) => rows.checked !== true
                        )
                      }
                      onChange={(e: any) => this.handleAllselect(e)}
                    />
                    <label
                      htmlFor="checkbox"
                      className="checkbox-custom-label"
                    ></label>
                  </div>
                  <label className="id">S.No</label>
                </th>
                <th>Name</th>
                <th>Account Email</th>
                <th>Role</th>
                <th>Role Category</th>
                <th>Document Management</th>
                <th>Project Management</th>
                <th>Planning</th>
                <th>Commercial</th>
                <th>Scheduling</th>

              </tr>
            </thead>
            <tbody>
              {this.state?.dbdata.map((each, index) => {
                return (
                  <>
                    <div className="checkbox_div">
                      <input
                        id={`checkbox${index}`}
                        className="checkbox-custom"
                        name="checkbox"
                        type="checkbox"
                        checked={each.checked}
                        onChange={(e) => this.handleSelectEachRow(e, each)}
                      />
                      <label
                        htmlFor={`checkbox${index}`}
                        className="checkbox-custom-label"
                      ></label>
                    </div>
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "color" : "nocolor"}
                    >
                      <td>
                        <label>{each.id}</label>
                      </td>
                      <td>{each.name}</td>
                      <td>{each.email}</td>
                      <td>{each.Roles?.name}</td>
                      <td>{each.RoleCategories?.name}</td>
                      <td>{each.Documents}</td>
                      <td>{each.Projects}</td>
                      <td>{each.Planning}</td>
                      <td>{each.Commercial}</td>
                      <td>{each.Scheduling}</td>

                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        {this.state.isAddButtonClicked && (
          <UserManagementModal
            // id={this.state.editData}
            // editData={this.state.editData}
            open={this.state.isAddButtonClicked}
            closeModal={() => this.closeModal()}
          />
        )}
      </div>
    );
  }
}

