import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
// import { utility } from './../utils/index';
import _ from '../utils/lodash';
import { api_urls } from './api_urls';
// let md5Blue = require('blueimp-md5');
// import { v4 as uuidV4 } from 'uuid';
// import { app_actions } from '../actions/app_actions';
// import app_config from '../config/app_config';
// import AppConfig from '../config/app_config';
// import { store } from './../store/index';
// import { informationalert } from './information_alert';
// import { loader } from './loader';
import AppConfig from '../config/app_config';

 
class APIService {
   axiosNoInterceptor: AxiosInstance;
   api_urls = api_urls;
   axiosOptions: AxiosRequestConfig = {
       timeout: 300000,
       withCredentials: true
   };
   ContentHeaders = {
       Json: 'application/json',
       FormData: 'multipart/form-data',
       Plain: 'text/plain',
       OctetStream: 'application/octet-stream'
   };
 
   BaseDomain = {
       BASE: undefined,
       Node: {
           Base: AppConfig.BASE_URL,
           Products: AppConfig.PRODUCTS_URL,
           Users: AppConfig.USERS,
           AWSHost: AppConfig.AWSHost,
           Multimedia: AppConfig.MEDIA_SERVICE,
           JavaMultimedia: AppConfig.JAVA_MEDIA_SERVICE
       },
       Java: {
        Auth: AppConfig.AUTHDomain,
        Sports: AppConfig.SPORTSDomain,
        Fantasy: AppConfig.FANTASY_API,
        Arena: AppConfig.ARENA_API,
        CaseBreakers: AppConfig.CASEBreaker,
        CardHunter: AppConfig.CARD_HUNTER_API,
        GoogleMapsApi: AppConfig.GOOGLE_MAPS_API
        // GoogleMapsApiKey: AppConfig.GoogleMapsApiKey
    },
   };
   constructor() {
       this.axiosNoInterceptor = axios.create();
 
       axios.interceptors.response.use((response:any) => {
        //    loader.hide();
        //    //Redirecting to login page
        //    if (response.status === 401) {
        //        informationalert.confirm(response.data.message, () => {
        //            for (let key in localStorage) {
        //                if (localStorage.hasOwnProperty(key)) {
        //                    if (key.indexOf('upshot') !== 0) {
        //                        localStorage.removeItem(key);
        //                    }
        //                }
        //            }
        //            window.location.replace(app_config.BASE_URL:string);
        //        });
        //    }
        //    if (response.data && response.status > 300) {
        //        utility.alert({ message: response.data.message });
        //    }
 
           return response;
       }, (error:any) => {
           if (error.response) {
            //    loader.hide();
               if (error.response.status === 401) {
                    window.location.replace('/');
               }
               return new Promise((resolve, reject) => {
                   if (error.response) {

                       console.log('failed axios with response key:', error.response);
                       reject(error.response.data);
                   } else {
                       console.log('failed axios:', error);
                       reject(error);
                   }
               });
           }
       });
   }
 
   getHeadersByType(requestMethod: string, headerType:any, domain: string, customHeaders?: any, payload?: any): any {
       let data:any = {};
       switch (headerType) {
           case this.ContentHeaders.Json: {
               data['Content-Type'] = 'application/json';
               break;
           }
           case this.ContentHeaders.Plain: {
               data['Content-Type'] = 'text/plain';
               break;
           }
           case this.ContentHeaders.FormData: {
               data['Content-Type'] = 'multipart/form-data';
               break;
           }
           case this.ContentHeaders.OctetStream: {
               data['Content-Type'] = 'multipart/form-data';
               break;
           }
           default: {
               data['Content-Type'] = 'application/octet-stream';
               break;
           }
       }
       let isAuthServer = domain.indexOf('9100') > -1;
    //    data['Authorization'] = app_config.AuthInfo.Token ? 'Bearer ' + app_config.AuthInfo.Token : '';

    let token:any = sessionStorage.getItem('token');
    // console.log(token)
    // console.log(token.replace(/['"]+/g, ''));

    if(token !== undefined && token !== null){
        console.log("here a135 apiservice")
        data['authorization'] = token ? 'Bearer ' + token.replace(/['"]+/g, '') : undefined;
    }

       if (requestMethod === 'get' || requestMethod === 'put' || requestMethod === 'post' || requestMethod === 'delete') {
        //    let nonce = uuidV4();
        //    let signature = data['Authorization'] + process.env.SIGNATURE_SECRET_KEY + nonce;
        //    if ((requestMethod === 'put' || requestMethod === 'post' || requestMethod === 'delete') && payload) {
        //        if (payload.attributes) {
        //            signature += JSON.stringify({ attributes: payload.attributes });
        //        }
        //    }
        //    if (!isAuthServer) {
        //        data['signature'] = md5Blue(signature);
        //        data['nonce'] = nonce;
        //    }
       }
 
       if (!isAuthServer) {
           data['os_type'] = navigator.userAgent.indexOf('Linux') !== -1 ? 'Linux' : navigator.userAgent.indexOf('Mac') !== -1 ? 'Mac' : 'Windows';
           data['app_version'] = process.env.app_version;
       }
       if (isAuthServer) {
           data['appid'] = process.env.APP_ID;
       }
       data = _.extend({}, data, customHeaders);
       return data;
   }
 
   post = (data: {
       endPoint: string;
       payLoad?: any;
       domain?: any;
       headerType?: string;
       customHeaders?: any;
       showLoader?: boolean;
       useNonInterceptor?: boolean;
   }) => {
       if (!data.domain) {
           data.domain = this.BaseDomain.BASE;
       }
       if (!data.headerType) {
           data.headerType = this.ContentHeaders.Json;
       }
 
       if (data.showLoader !== false) {
           data.showLoader = true;
       }
       if (!navigator.onLine) {
 
       }
 
       if (!data.useNonInterceptor) {
           data.useNonInterceptor = false;
       }
 
       if (data.useNonInterceptor) {
           let payLoadData = data.payLoad instanceof FormData ? data.payLoad : JSON.stringify(data.payLoad);
           return this.axiosNoInterceptor.post(data.endPoint,
               payLoadData, {
               timeout: this.axiosOptions.timeout,
               transformRequest: this.axiosOptions.transformRequest,
               baseURL: data.domain,
               headers: this.getHeadersByType(data.headerType, data.domain, data.customHeaders)
           });
 
       } else {
           let payLoadData = data.payLoad instanceof FormData ? data.payLoad : JSON.stringify(data.payLoad);
           return axios.post(data.endPoint,
               payLoadData, {
               timeout: this.axiosOptions.timeout,
               transformRequest: this.axiosOptions.transformRequest,
               baseURL: data.domain,
               headers: this.getHeadersByType('post', data.headerType, data.domain, data.customHeaders, data.payLoad)
           });
 
       }
   }
 
//    isNetworkError = (error) => {
//        return !error.response && error.code !== 'ECONNABORTED';
//    }
 
//    retry = (config: AxiosRequestConfig) => {
//        return axios(config);
//    }
   put = (data: {
       endPoint: string;
       payLoad?: any;
       domain?: any;
       id?: string;
       headerType?: string;
       customHeaders?: any;
       showLoader?: boolean;
   }) => {
 
       if (!data.domain) {
           data.domain = this.BaseDomain.BASE;
       }
       if (!data.headerType) {
           data.headerType = this.ContentHeaders.Json;
       }
       if (data.showLoader !== false) {
           data.showLoader = true;
       }
 
       let payLoadData = data.payLoad instanceof FormData ? data.payLoad : JSON.stringify(data.payLoad);
       return axios.put(data.endPoint,
           payLoadData, {
           timeout: this.axiosOptions.timeout,
           transformRequest: this.axiosOptions.transformRequest,
           baseURL: data.domain,
           headers: this.getHeadersByType('put', data.headerType, data.domain, data.customHeaders, data.payLoad)
       });
   }
 
   delete = (data: {
       endPoint: string;
       payLoad?: any;
       domain?: any;
       id?: string;
       headerType?: string;
       customHeaders?: any;
       showLoader?: boolean
   }) => {
 
       if (!data.domain) {
           data.domain = this.BaseDomain.BASE;
       }
       if (!data.headerType) {
           data.headerType = this.ContentHeaders.Json;
       }
 
       if (data.showLoader !== false) {
           data.showLoader = true;
       }
 
       return axios.delete(data.endPoint, {
           baseURL: data.domain,
           data: data.payLoad,
           headers: this.getHeadersByType('delete', data.headerType, data.domain, data.customHeaders, data.payLoad)
       });
   }
 
   get = (data: {
       endPoint: string;
       domain: any;
       payLoad?: any;
       id?: string;
       headerType?: string;
       customHeaders?: any;
       showLoader?: boolean;
       noHeadersRequired?: boolean;
   }) => {
       if (!data.domain) {
           data.domain = this.BaseDomain.BASE;
       }
       if (!data.headerType) {
           data.headerType = this.ContentHeaders.Json;
       }
       if (data.showLoader || data.showLoader === undefined) {
           data.showLoader = true;
       }

       try {
           return axios.get(data.endPoint, {
               baseURL: data.domain,
               timeout: this.axiosOptions.timeout,
               params: data.payLoad,
               headers: data.noHeadersRequired ? null : this.getHeadersByType('get', data.headerType, data.domain, data.customHeaders, data.payLoad)
           });
       } catch (e) {
           console.error('axios get::', e);
       }
   }
//    fileUpload = (data: {
//        endPoint: string;
//        payLoad?: any;
//        domain?: string;
//        id?: string;
//        headerType?: string;
//        customHeaders?: any;
//        showLoader?: boolean;
//        noHeadersRequired?: boolean;
//        fileName?: string;
//    }) => {
//        if (!data.domain) {
//            data.domain = this.BaseDomain.Node.Multimedia;
//        }
//        if (!data.headerType) {
//            data.headerType = this.ContentHeaders.Json;
//        }
//        if (data.showLoader !== false) {
//            data.showLoader = true;
//        }
//        if (data.showLoader) {
//        }
//        let payLoadData = data.payLoad;
//        let config = {};
//        // Loader percentage related code
//        if (data.id && data.id !== '') {
//            let indexToUpdate = undefined;
//            config = {
//                onUploadProgress: (progressEvent) => {
//                    let percentage = (Math.round((progressEvent.loaded * 100) / progressEvent.total));
//                    let uploadedData = _.clone(store.getState().App.percentageData);
//                    let percentageData = {
//                        id: data.id,
//                        name: data.fileName,
//                        percentage: `${percentage} %`,
//                        per_num: percentage
//                    };
//                    // let currentIDData = uploadedData.find((element) => { return element.id === data.id });
 
//                    if (indexToUpdate > -1 || percentage === 100) {
//                        /**
//                         * if part will execute 2nd time
//                         * on wards for each uploaded data
//                         */
//                        if (percentage !== 100 && uploadedData) {
//                            uploadedData[indexToUpdate].percentage = `${percentage} %`;
//                            uploadedData[indexToUpdate].per_num = percentage;
//                        } else {
//                            /**
//                             * If uploading data reaches to 100%
//                             * than clean the data from reducer
//                             */
//                            setTimeout(() => {
//                                uploadedData.splice(indexToUpdate, 1);
//                            }, 2000);
//                        }
//                    } else {
//                        /**
//                         * This else part will execute only one time for
//                         * each newly uploaded data
//                         */
//                        indexToUpdate = uploadedData.length;
//                        uploadedData.push(percentageData);
//                    }
//                    store.dispatch(app_actions.updateUploadPercentage(uploadedData));
//                },
//                headers: { 'Content-Type': payLoadData.type }
//            };
//        } else {
//            config = {
//                headers: { 'Content-Type': payLoadData.type }
//            };
//        }
//        return axios.post(`${data.domain}${data.endPoint}`, payLoadData, config);
//    }
//    AWSput = (data: {
//        endPoint: string;
//        payLoad?: any;
//        domain?: string;
//        id?: string;
//        headerType?: string;
//        customHeaders?: any;
//        showLoader?: boolean;
//    }) => {
//        if (!data.domain) {
//            data.domain = this.BaseDomain.Node.Multimedia;
//        }
 
//        if (!data.headerType) {
//            data.headerType = this.ContentHeaders.Plain;
//        }
//        if (data.showLoader !== false) {
//            data.showLoader = true;
//        }
//        let endPointUrl = utility.replacePostUrl(data.endPoint) + ((data.id) ? '/' + data.id : '');
//        return this.checkForSession(axios.put(endPointUrl,
//            data.payLoad, {
//            baseURL: data.domain,
//            headers: {
//                'Content-Type': 'text/plain'
//            }
//        }));
//    }
//    AWSPost = (data: {
//        endPoint: string;
//        payLoad?: any;
//        domain?: string;
//        id?: string;
//        headerType?: string;
//        customHeaders?: any;
//        showLoader?: boolean;
//    }) => {
//        if (!data.domain) {
//            data.domain = this.BaseDomain.Node.Multimedia;
//        }
 
//        if (!data.headerType) {
//            data.headerType = this.ContentHeaders.Plain;
//        }
//        if (data.showLoader !== false) {
//            data.showLoader = true;
//        }
//        let endPointUrl = utility.replacePostUrl(data.endPoint) + ((data.id) ? '/' + data.id : '');
//        return this.checkForSession(axios.post(endPointUrl,
//            data.payLoad, {
//            baseURL: data.domain,
//            headers: {
//                'Content-Type': 'text/plain'
//            }
//        }));
//    }
//    downloadFile = (data: {
//        endPoint: string;
//        domain: string;
//        payLoad?: any;
//        id?: string;
//        headerType?: string;
//        customHeaders?: any;
//        showLoader?: boolean;
//        noHeadersRequired?: boolean;
//    }) => {
//        if (!data.domain) {
//            data.domain = this.BaseDomain.BASE;
//        }
//        if (!data.headerType) {
//            data.headerType = this.ContentHeaders.Json;
//        }
//        if (data.showLoader || data.showLoader === undefined) {
//            data.showLoader = true;
//        }
//        if (data.showLoader) {
//            loader.show();
//        }
 
//        try {
//            return axios.get(data.endPoint, {
//                baseURL: data.domain,
//                timeout: this.axiosOptions.timeout,
//                params: data.payLoad,
//                responseType: 'arraybuffer',
//                headers: data.noHeadersRequired ? null : this.getHeadersByType('get', data.headerType, data.domain, data.customHeaders)
//            });
//        } catch (e) {
//            console.error('axios get::', e);
//        }
//    }
 
//    getPDFDownload = (data: {
//        domain: string;
//    }) => {
//        try {
//            return axios.get('', {
//                baseURL: data.domain,
//                responseType: 'blob'
//            });
//        } catch (e) {
//            console.error('axios get::', e);
//        }
//    }
 
//    getFileData = (data: {
//        endPoint: string;
//        domain: string;
//        payLoad?: any;
//        id?: string;
//        headerType?: string;
//        customHeaders?: any;
//        showLoader?: boolean;
//        noHeadersRequired?: boolean;
//    }) => {
//        if (!data.domain) {
//            data.domain = this.BaseDomain.BASE;
//        }
//        if (!data.headerType) {
//            data.headerType = this.ContentHeaders.Json;
//        }
//        if (data.showLoader || data.showLoader === undefined) {
//            data.showLoader = true;
//        }
//        if (data.showLoader) {
//            loader.show();
//        }
 
//        try {
//            return axios.get(data.endPoint, {
//                baseURL: data.domain,
//                timeout: this.axiosOptions.timeout,
//                params: data.payLoad,
//                headers: data.noHeadersRequired ? null : this.getHeadersByType('get', data.headerType, data.domain, data.customHeaders)
//            });
//        } catch (e) {
//            console.error('axios get::', e);
//        }
//    }
 
//    checkForSession(promise: AxiosPromise) {
//        // session expired : 401
//        promise.catch(err => {
//            if (AppConfig.ENV !== 'development' && err.status === 401) {
//                localStorage.clear();
//            }
//        });
 
//        return promise;
//    }
 
}
export const api_service = new APIService();
 
 
 
