// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-login-navbar{
    background-color: #153D77;
    padding: 0;
    height: 56px;
    gap: 16px;
    /* position: sticky;
    top:0;
    z-index: 2;
    overflow: scroll; */
    z-index: 2;
    position: sticky;
    top: 0;
    align-items: center;
}

.right_body{
    position: sticky;
    top: 0;
    z-index: 1;
}

.profil_pic{
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.nav_dropdown{
    margin-top: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}`, "",{"version":3,"sources":["webpack://./src/features/navBar/navbarr.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,YAAY;IACZ,SAAS;IACT;;;uBAGmB;IACnB,UAAU;IACV,gBAAgB;IAChB,MAAM;IACN,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,2CAA2C;AAC/C","sourcesContent":[".user-login-navbar{\r\n    background-color: #153D77;\r\n    padding: 0;\r\n    height: 56px;\r\n    gap: 16px;\r\n    /* position: sticky;\r\n    top:0;\r\n    z-index: 2;\r\n    overflow: scroll; */\r\n    z-index: 2;\r\n    position: sticky;\r\n    top: 0;\r\n    align-items: center;\r\n}\r\n\r\n.right_body{\r\n    position: sticky;\r\n    top: 0;\r\n    z-index: 1;\r\n}\r\n\r\n.profil_pic{\r\n    display: flex;\r\n    align-items: center;\r\n    margin-right: 16px;\r\n}\r\n\r\n.nav_dropdown{\r\n    margin-top: 10px;\r\n    background-color: white;\r\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
