import React, { useEffect, useRef, useState } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import "./roiCalculator.scss"

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const RoiCalculator = () => {
  const [projectBudget, setProjectBudget] = useState<number>(5000000000);
  const [duration, setDuration] = useState<number>(50);
  const [softwareUserCount, setSoftwareUserCount] = useState<number>(50);
  const [timeSaved, setTimeSaved] = useState<number>(2.5);
  const [saleableArea, setSaleableArea] = useState<number>(45000);
  const [perMonthPricing] = useState<number>(245000);
  const inputRef = useRef<HTMLInputElement>(null);

  const projectManagementFees: number = projectBudget * 0.02;
  const designConsultancyFees: number = projectBudget * 0.018;
  const totalProductCost: number = duration * perMonthPricing;
  // const roi: number = projectBudget - (projectManagementFees + designConsultancyFees + totalProductCost);
  const roiTotal: number = (duration / 12) * (softwareUserCount * timeSaved * saleableArea);
  const roi = Math.floor(roiTotal)
  let total = roi + totalProductCost

  const [pieData, setPieData] = useState({
    labels: ['Project Budget', 'PMC Fees', 'DC Fees', 'ROI', 'Product Pricing'],
    datasets: [
      {
        data: [projectBudget, projectManagementFees, designConsultancyFees, total],
        backgroundColor: ['#62B2FD', '#FFB44F ', '#F99BAB', '#997300 '],
      }
    ]
  });

  const barData = {
    labels: ['Product Cost', 'ROI'],
    datasets: [
      {
        data: [totalProductCost],
        backgroundColor: '#9BDFC4 '
      },
      {
        data: [roi],
        backgroundColor: '#9F97F7 '
      }
    ]
  };
  useEffect(() => {
    const updatedPieData = {
      ...pieData,
      datasets: [
        {
          ...pieData.datasets[0],
          data: [projectBudget, projectManagementFees, designConsultancyFees, total]
        }
      ]
    };
    setPieData(updatedPieData);
  }, [projectBudget, roi]);


  const pieOptions: any = {
    rotation: 90,
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    plugins: {
      legend: {
        position: 'none',
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return tooltipItem.label + ': ' + tooltipItem.raw.toLocaleString();
          }
        }
      },
      // datalabels: {
      //   color: '#fff',
      //   formatter: function (value: any) {
      //     return value.toLocaleString();
      //   }
      // }
    },
    maintainAspectRatio: false
  };

  const barOptions: any = {
    with: "150px",
    plugins: {
      legend: {
        position: 'none',
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return tooltipItem.label + ': ' + tooltipItem.raw.toLocaleString();
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    },
    maintainAspectRatio: false
  };


  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (document.activeElement === inputRef.current) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement === inputRef.current && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
        event.preventDefault();
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel);
      inputElement.addEventListener('keydown', handleKeyDown);

      return () => {
        inputElement.removeEventListener('wheel', handleWheel);
        inputElement.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, []);

  const calculatePercentage = (value: number, min: number, max: number) => {
    return ((value - min) / (max - min)) * 100;
  };
  const percentage = calculatePercentage(timeSaved, 0.5, 24);

  const min = 1;
  const max = 500;
  const userCountCalculatePercentage = (value: number, min: number, max: number): number => {
    return ((value - min) / (max - min)) * 100;
  };

  const userCountPercentage = userCountCalculatePercentage(softwareUserCount, min, max);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.]/g, '');
    const parsedValue = parseFloat(numericValue);
    setProjectBudget(isNaN(parsedValue) ? 0 : parsedValue);
  };

   // Handle input change
   const handleSaleableArea = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.]/g, '');
    const parsedValue = parseFloat(numericValue);
    setSaleableArea(isNaN(parsedValue) ? 0 : parsedValue);
  };

  return (
    <div className='row roi-charts'>
      <div className='col-md-5 details'>
        <div className="row">
          <div className="col-md-6 ">
            <div className="card p-3 budget">
              <div className="form-group no-spinners">
                <label>Project Budget - INR</label>
                {/* <input
                  type="number"
                  ref={inputRef}
                  className="form-control"
                  value={projectBudget}
                  onChange={(e: any) => setProjectBudget(e.target.value)}
                /> */}
                <input
                  type="text"
                  ref={inputRef}
                  className="form-control"
                  value={projectBudget.toLocaleString('en-IN')}
                  onChange={handleInputChange}
                  placeholder="Enter budget"
                />
              </div>
              <div className="form-group">
                <label>Duration in Months</label>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={duration}
                  onChange={e => setDuration(Number(e.target.value))}
                  style={{
                    background: `linear-gradient(to right, #C00000 0%, #C00000 ${duration}%, #A1B1C9 ${duration}%, #A1B1C9 100%)`,
                    border: 'none',
                    height: '4px',
                    appearance: 'none',
                    width: '100%'
                  }}
                />
                <div>{duration}</div>
              </div>
              <div className="form-group">
                <label>Software User Count</label>
                <input
                  type="range"
                  className="form-control-range"
                  min="1"
                  max="500"
                  style={{
                    background: `linear-gradient(to right, #C00000 0%, #C00000 ${userCountPercentage}%, #A1B1C9 ${userCountPercentage}%, #A1B1C9 100%)`,
                    border: 'none',
                    height: '4px',
                    appearance: 'none',
                    width: '100%'
                  }}
                  value={softwareUserCount}
                  onChange={e => setSoftwareUserCount(Number(e.target.value))}
                />
                <div>{softwareUserCount}</div>
              </div>
              <div className="form-group">
                <label>Time saved on Reports Weekly - Hr's</label>
                <input
                  type="range"
                  className="form-control-range"
                  min="0.5"
                  max="24"
                  step="0.5"
                  style={{
                    background: `linear-gradient(to right, #C00000 0%, #C00000 ${percentage}%, #A1B1C9 ${percentage}%, #A1B1C9 100%)`,
                    border: 'none',
                    height: '4px',
                    appearance: 'none',
                    width: '100%'
                  }}
                  value={timeSaved}
                  onChange={e => setTimeSaved(Number(e.target.value))}
                />
                <div>{timeSaved}</div>
              </div>
              <div className="form-group">
                <label>Average Monthly Salary - INR</label>
                {/* <input
                  type="number"
                  ref={inputRef}
                  className="form-control"
                  value={saleableArea}
                  onChange={(e: any) => setSaleableArea(e.target.value)}
                /> */}
                <input
                  type="text" // Use text to handle formatted input
                  ref={inputRef}
                  className="form-control"
                  value={saleableArea.toLocaleString('en-IN')}
                  onChange={handleSaleableArea}
                  placeholder="Enter saleable area"
                />
              </div>
              <div className="form-group">
                <label>Per Month Pricing - INR</label>
                <input type="text" className="form-control" value={perMonthPricing.toLocaleString('en-IN')} readOnly />
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="card p-3 end-to-end">
              <h5 className="text-center cct-header">With Constructcoretech</h5>
              <h6 className="text-center cct-header" style={{ paddingBottom: "7px" }}>End - To - End</h6>
              <hr style={{ marginBottom: "34px", height: "4px", background: "linear-gradient(45deg, #C00000 0%, #153D77 52%, #738BAD 100%)" }} />
              <div className="form-group">
                <label>Project Budget - INR</label>
                <p>{projectBudget.toLocaleString('en-IN')}</p>
              </div>
              <div className="form-group">
                <label>Project Management Consultancy Fees - INR</label>
                <p>{projectManagementFees.toLocaleString('en-IN')}</p>
              </div>
              <div className="form-group">
                <label>Design Consultancy Fees - INR</label>
                <p>{designConsultancyFees.toLocaleString('en-IN')}</p>
              </div>
              <div className="form-group">
                <label>Total Product Cost - INR</label>
                <p>{totalProductCost.toLocaleString('en-IN')}</p>
              </div>
            </div>
            <div className="roi">
              <label>ROI :</label>
              <p>{roi.toLocaleString('en-IN')} INR</p>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-7 charts'>
        <div className='row' style={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
          <div className='col-6'>
            <Pie style={{ height: "397px", width: "397px" }} height="397px" width="397px" data={pieData} options={pieOptions} />
          </div>
          <div className='col-6'>
            <Bar height="295px" width="295px" style={{ width: "295px", height: "295px" }} data={barData} options={barOptions} />
          </div>
          <div className='col-12'>
            <ul style={{ gap: "30px", display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
              <li style={{ display: "flex", alignItems: "center", fontSize:"18px" }}>
                <p style={{ height: "10px", width: "10px", backgroundColor: "#62B2FD", marginRight: "10px" }}></p>
                <p>Project Budget</p><p style={{ color: "#14213C", paddingLeft: "16px", fontWeight: "bold" }}>{projectBudget.toLocaleString('en-IN')}</p>
              </li>
              <li style={{ display: "flex", alignItems: "center", fontSize:"18px" }}>
                <p style={{ height: "10px", width: "10px", backgroundColor: "#FFB44F", marginRight: "10px" }}></p>
                <p>PMC Fees</p><p style={{ color: "#14213C", paddingLeft: "16px", fontWeight: "bold" }}>{projectManagementFees.toLocaleString('en-IN')}</p>
              </li>
              <li style={{ display: "flex", alignItems: "center", fontSize:"18px" }}>
                <p style={{ height: "10px", width: "10px", backgroundColor: "#F99BAB", marginRight: "10px" }}></p>
                <p>DC Fees</p><p style={{ color: "#14213C", paddingLeft: "16px", fontWeight: "bold" }}>{designConsultancyFees.toLocaleString('en-IN')}</p>
              </li>
              <li style={{ display: "flex", alignItems: "center", fontSize:"18px" }}>
                <p style={{ height: "10px", width: "10px", backgroundColor: "#9BDFC4", marginRight: "10px" }}></p>
                <p>Product Cost</p><p style={{ color: "#14213C", paddingLeft: "16px", fontWeight: "bold" }}>{totalProductCost.toLocaleString('en-IN')}</p>
              </li>
              <li style={{ display: "flex", alignItems: "center", fontSize:"18px" }}>
                <p style={{ height: "10px", width: "10px", backgroundColor: "#9F97F7", marginRight: "10px" }}></p>
                <p>ROI</p><p style={{ color: "#14213C", paddingLeft: "16px", fontWeight: "bold" }}>{roi.toLocaleString('en-IN')}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoiCalculator;
