import React, { Component } from 'react'
import { api_service } from '../../api/api_service';

export default class ContactUs extends Component {

    state = {
        first_name: "",
        last_name: "",
        company: "",
        buss_email: "",
        job_title: "",
        phone_num: 0
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    contactDetails() {
        let payload: any = {
            attributes: {
                type:1,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                company: this.state.company,
                buss_email: this.state.buss_email,
                job_title: this.state.job_title,
                phone_num: this.state.phone_num
            },
        };
        return payload;
    }

    handleSubmit(e: any) {
        console.log(this.contactDetails());
        e.preventDefault();
        try {
            api_service
                .post({
                    endPoint: api_service.api_urls.CONTACTUS,
                    domain: "http://44.215.80.246:4000",
                    payLoad: this.contactDetails(),
                })
                .then((res:any) => {
                    if (res.data.status === 200) {
                        console.log(res.data.status);
                    }
                })
                .catch((err: any) => {
                    console.log(err.message);
                });
        } catch (error: any) {
            console.log(error.message);

        }
    }
    render() {
        return (
            <>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="submit-form row d-flex">


                        <div className="col-md-6">

                            <input onChange={this.handleChange} name="first_name" placeholder="First Name" type="text" required/>

                        </div>

                        <div className="col-md-6">

                            <input onChange={this.handleChange} name="last_name" placeholder="Last Name" type="text" required/>

                        </div>

                        <div className="col-md-6">

                            <input onChange={this.handleChange} name="company" placeholder="Company" type="text" required/>

                        </div>

                        <div className="col-md-6">

                            <input onChange={this.handleChange} name="phone_num" placeholder="Phone Number" type="number" required/>

                        </div>

                        <div className="col-md-6">

                            <input onChange={this.handleChange} name="buss_email" placeholder="Business Mail" type="text" required/>

                        </div>

                        <div className="col-md-6">

                            <input onChange={this.handleChange} name="job_title" placeholder="Job Title" type="text" required/>

                        </div>

                        <div className="col-md-12">

                            <button type='submit' className="submit-btn">Submit Now</button>

                        </div>

                    </div>
                </form>
            </>
        )
    }
}
