import React, { Suspense } from 'react';
import Layout from './components/layout/Layout'
import { Route, Routes } from 'react-router-dom';
import Home from './features/Home';
// import About from './features/About';
import Pricing from './features/pricing/Pricing';
import Features from './features/Features';
import PrivacyPolicy from './features/PrivacyPolicy';
import Faq from './features/Faq';
import Support from './features/Support';
import Contact from './features/Contact';
import Career from './features/Career';
import ApplyJob from './features/ApplyJob';
import Login from './features/auth/Login';
import ProtectedRoutes from './routes/Protected-routes';
import UserManagment from './features/user managment/UserManagment';
import Projects from './features/projects/Projects';
import ContactList from './features/contact/ContactList';
const About = React.lazy(() => import('./features/About'))

function App() {
  return (
    <Suspense>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/features' element={<Features />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='/faq' element={<Faq/>}/>
          <Route path='/support' element={<Support/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/career' element={<Career/>}/>
          <Route path='/apply-job' element={<ApplyJob />} />
        </Route>
        <Route path='/login' element={<Login />}></Route>
        <Route element={<ProtectedRoutes/>}>
          <Route path='/usermanagment' element={<UserManagment />}></Route>
          <Route path='/projects' element={<Projects />}></Route>
          <Route path='/contact-list' element={<ContactList />}></Route>
        </Route>
      </Routes>
    </Suspense>
  );
}


export default App;
