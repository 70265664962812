import React, { Component } from "react";
// import { api_service } from "../api/api_service";
import { Modal, Form } from "react-bootstrap";
import shape from "../images/Shape.png";
import Accordion from "react-bootstrap/Accordion";
import "./modal.scss"
import { api_service } from "../../api/api_service";
// import { isEmail, ReactMultiEmail } from "react-multi-email";
// import moment from "moment";

interface UsermanagementPayload {
  id?: any;
  name: string;
  account: string;
  gst: string;
  plan_id: number;

}

interface usermanagementState {
  id: any;
  name: string;
  account: string;
  gst: string;
  plan_id: number;

}

interface insuranceProps {
  open: boolean;
  closeModal: Function;
  editData?: any;
  id?: any;
}

export default class UserFormModal extends Component<
  insuranceProps,
  usermanagementState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: this.props.id,
      name: "",
      account: "",
      gst: "",
      plan_id:0,
    };
  }
  

  getUserFormPayload() {
    let userManagmentPayload: UsermanagementPayload = {
      name: this.state.name,
      account: this.state.account,
      gst: this.state.gst,
      plan_id: this.state.plan_id
    };

    let payload: any = {
      attributes: {
        id: this.state.id,
        name: userManagmentPayload.name,
        account: userManagmentPayload.account,
        gst: userManagmentPayload.gst,
        plan_id: userManagmentPayload.plan_id

      },
    };
    return payload;
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  handledevChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ gst: e.target.value });
    this.setState((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    // this.fetchProjectsData();
  };

  async loadScript(src: any) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}


  async handleSubmit(e: any) {
    e.preventDefault();
   // alert(this.props.id)
    let plan_id = this.props.id;
    let email = this.state.account;
    let name = this.state.name;
    let gst = this.state.gst;
    
    const res = await this.loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
    }

    var options = {
        "key": "rzp_test_Okjl451cQAmZCs",
        "amount": 0, // 2000 paise = INR 20, amount in paisa
        "name": "Merchant Name",
        'order_id': "",
        "handler": async function (response: any) {
            console.log(response);
            var values: any = {
                razorpay_signature: response.razorpay_signature,
                razorpay_order_id: response.razorpay_order_id,
                transactionid: response.razorpay_payment_id,
                transactionamount: plan_id
            }

            try {
                await api_service
                    .post({
                        endPoint: "/v1/upgrade/payment",
                        domain: "http://44.215.80.246:4007",
                        payLoad: values
                    })
                    ?.then((res) => {


                        if (res.data.status === 200) {
                            window.open("http://44.215.80.246:3000", '_blank');


                            // window.location.href=`http://44.215.80.246:3000`;

                            //setTimeout(this.props.closeModal, 1000);

                            // //console.log("----------")
                            // //console.log(JSON.stringify(this.state.data))
                        }
                    })
                    .catch((err) => {
                        //console.log(err);
                    });
            } catch (err) {
                console.log(err);
            }

            //   api_service.post('http://44.215.80.246:5000/upgrade/payment',values)
            //   .then(res=>{alert("Success")})
            //   .catch(e=>console.log(e))


        },
        "theme": {
            "color": "#528ff0"
        }
    };


    try {

        let payload: any = {
            attributes: {
                plan_id: plan_id,
                email: email,
                "name": name,
                "gst": gst
            },
        };


        await api_service
            .post({
                endPoint: "/v1/generateOrderTokenRazorpay",
                domain: "http://44.215.80.246:4007",
                payLoad: payload
            })
            ?.then((res) => {

                console.log(res.data.data)
                if (res.data.status === 200) {
                    alert("Success")

                    options.order_id = res.data.data.id;
                    options.amount = res.data.data.amount;
                    console.log(options)
                    var rzp1 = new window.Razorpay(options);

                    rzp1.open();


                    // //console.log("----------")
                    // //console.log(JSON.stringify(this.state.data))

                }
            })
            .catch((err) => {
                //console.log(err);
            });
    } catch (err) {
        console.log(err);
    }
  }

  getgstPayload() {
    let payload: any = {
      attributes: {
        user_id: sessionStorage.getItem("user_id"),
        page: 1,
        limit: 20,
      },
    };
    return payload;
  }

  // closeModal() {
  //   this.setState((p) => ({ isAddButtonClicked: false }));
  // }
  
  render() {
    console.log(this.state);
    return (
      <Modal
        size="lg"
        show={this.props.open}
        onHide={() => this.props.closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>NEW MEMBER</Modal.Title>
        </Modal.Header>
        <Form
          className="commercial_form"
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Body>
            <Accordion
              className="commercial_accordian"
              defaultActiveKey={["0", "1", "2", "3"]}
              alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>CLIENT INFO</Accordion.Header>
                <Accordion.Body>
                  <div className="row border-bottom pt-4">
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="">Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        className=" "
                        required
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="">Account Email</Form.Label>
                      <Form.Control
                        type="text"
                        className=""
                        required
                        name="account"
                        value={this.state.account}
                        onChange={this.handleChange}
                      />
                    </div>
                
                    <div className="col-md-6 col-12 input_div">
                      <Form.Label className="">GST</Form.Label>
                      <Form.Control
                        type="text"
                        className=""
                        required
                        name="gst"
                        value={this.state.gst}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="col-md-6 col-12 input_div">
                      {/* <Form.Label className="">Plan</Form.Label> */}
                      <Form.Control
                        type="hidden"
                        className=""
                        required
                        name="plan_id"
                        value={this.props.id}
                        onChange={this.handleChange}
                      />
                    </div>

                 
                  
                  </div>
                </Accordion.Body>
              </Accordion.Item>
         
            </Accordion>
            <div className="commercial_footer d-flex flex-row justify-content-between">
              <button
                className="modal_cancel_btn"
                onClick={() => this.props.closeModal()}
              >
                CANCEL
              </button>
              <button className="modal_save_btn" type="submit">
                SUBMIT
              </button>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}
