import React, { useEffect } from 'react'
import ContactUs from '../components/common/ContactUs'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Features() {
    useEffect(()=>{
        var owl = $(".features-carousel-sec .owl-carousel");

        if (owl.length > 0) {

            owl.owlCarousel({

                items: 1,

                navText: ['<i class="icon-know-more-arrow" ></i>', '<i class="icon-know-more-arrow " ></i>'],

                navigation: true,

                controls: true,

                autoPlay: true

            });

        }
    },[])
    return (
        <div>

            <section className="inner-banner features-banner">

                <div className="container">

                    <div className="contents">

                        <h1>Features</h1>

                        <p>Unlock the full potential of your construction projects with ConstructCoreTech's innovative and feature-rich platform. Embrace the future of construction management and revolutionize your work processes today.</p>

                    </div>

                </div>

            </section>

            <section className="more-features padding-lg">

                <div className="container">

                    <div className="row">

                        <div className="col-lg-5">

                            <h2>Constructcoretech has more features than you think</h2>

                            <OwlCarousel className='owl-theme' margin={10} loop
                                autoplay={true}
                                autoplayTimeout={5000}
                                nav
                                dots={false}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    768: {
                                        items: 1
                                    },
                                    992: {
                                        items: 1
                                    }
                                }}
                            >
                                <div className='item'>
                                    <ul className="icon-tik-list">
                                        <li style={{ borderBottom: "none" }}>
                                            <p style={{ padding: "0" }}>Experience the Next Level: Our construction management software, ConstructCoreTech, is equipped with an array of powerful features that go beyond the ordinary.</p>
                                        </li>
                                        <li style={{ borderBottom: "none" }}>
                                            <p style={{ padding: "0" }}>A Comprehensive Solution: From document management to advanced data analytics, our platform covers every aspect of your construction projects.</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <ul className="icon-tik-list">
                                        <li style={{ borderBottom: "none" }}>
                                            <p style={{ padding: "0" }}>Tailored for Efficiency: Designed to streamline workflows and enhance productivity, ConstructCoreTech comes with intuitive tools to simplify complex tasks.</p>
                                        </li>
                                        <li style={{ borderBottom: "none" }}>
                                            <p style={{ padding: "0" }}>Empower Your Team: With additional functions and customizability, our software empowers your team to excel in their roles.</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <ul className="icon-tik-list">
                                        <li style={{ borderBottom: "none" }}>
                                            <p style={{ padding: "0" }}>Stay Ahead of the Curve: Keep pace with industry advancements and stay ahead of the competition with ConstructCoreTech's cutting-edge features.</p>
                                        </li>
                                        <li style={{ borderBottom: "none" }}>
                                            <p style={{ padding: "0" }}>Stay Ahead of the Curve: Keep pace with industry advancements and stay ahead of the competition with ConstructCoreTech's cutting-edge features.</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className='item'>
                                    <ul className="icon-tik-list">
                                        <li style={{ borderBottom: "none" }}>
                                            <p style={{ padding: "0" }}>Elevate Your Projects: Experience enhanced collaboration, seamless communication, and efficient project management with ConstructCoreTech.</p>
                                        </li>
                                    </ul>
                                </div>

                            </OwlCarousel>

                        </div>

                        <div className="col-lg-7">
                        
                            {/* <div className="img-holder"><a className="play-btn video" href="https://www.youtube.com/watch?v=3xJzYpRVQVA"><span className="icon-play-btn"></span></a> */}
                            <div className="img-holder"><a className="play-btn video" href="https://www.youtube.com/watch?v=ibIQxVWP5cg"><span className="icon-play-btn"></span></a>

                                <figure className="img"><img src="images/more-features-img.png" className="img-fluid" alt="" /></figure>

                            </div>

                        </div>

                    </div>

                </div>

            </section>


            <section className="awesome-design padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10">

                            <h2>"Embrace Brilliance: Stunning Design, Seamless Functionality"</h2>

                            <p>Experience the perfect blend of awe-inspiring design and unparalleled functionalities that will revolutionize the way you manage construction projects.</p>

                        </div>

                    </div>

                    <div className="vertical-tab-outer clearfix">

                        <div className="tab-area">

                            <div className="tabs-vertical">

                                <ul>

                                    <li className="active" rel="tab-1">

                                        <figure className="icon-div"><img src="images/Captivating-Design.png" className="rounded-circle img-fluid" alt="" /></figure>

                                        <div className="text-div">

                                            <h4>Captivating Design</h4>

                                            <p>Immerse yourself in a visually stunning interface that elevates the user experience to new heights.</p>

                                        </div>

                                    </li>

                                    <li rel="tab-2" className="">

                                        <figure className="icon-div"><img src="images/Intuitive-Navigation.png" className="rounded-circle img-fluid" alt="" /></figure>

                                        <div className="text-div">

                                            <h4>Intuitive Navigation</h4>

                                            <p>Seamlessly explore our platform with easy-to-use features and user-friendly controls.</p>

                                        </div>

                                    </li>

                                    <li rel="tab-3">

                                        <figure className="icon-div"><img src="images/Cutting-Edge-Technology.png" className="rounded-circle img-fluid" alt="" /></figure>

                                        <div className="text-div">

                                            <h4>Cutting-Edge Technology</h4>

                                            <p>Stay ahead of the curve with the latest advancements in construction software.</p>

                                        </div>

                                    </li>

                                    <li rel="tab-4">

                                        <figure className="icon-div"><img src="images/Empowering-Flexibility.png" className="rounded-circle img-fluid" alt="" /></figure>

                                        <div className="text-div">

                                            <h4>Empowering Flexibility</h4>

                                            <p>Our platform adapts to your projects, empowering you to achieve your goals.</p>

                                        </div>

                                    </li>

                                </ul>

                            </div>


                            <div className="tab-vertical-container">


                                <div className="tab-drawer-heading active-item" rel="tab-1">

                                    <div className="text-div">

                                        <h4>Captivating Design</h4>

                                    </div>

                                </div>

                                <div id="tab-1" className="tab-vertical-content" >

                                    <figure><img src="images/awesome-design-img.png" alt="" /></figure>

                                </div>

                                <div className="tab-drawer-heading" rel="tab-2">

                                    <div className="text-div">

                                        <h4>Intuitive Navigation</h4>

                                    </div>

                                </div>

                                <div id="tab-2" className="tab-vertical-content" >

                                    <figure><img src="images/awesome-design-img.png" alt="" /></figure>

                                </div>


                                <div className="tab-drawer-heading" rel="tab-3">

                                    <div className="text-div">

                                        <h4>Cutting-Edge Technology</h4>

                                    </div>

                                </div>

                                <div id="tab-3" className="tab-vertical-content" >

                                    <figure><img src="images/awesome-design-img.png" alt="" /></figure>

                                </div>

                                <div className="tab-drawer-heading" rel="tab-4">

                                    <div className="text-div">

                                        <h4>Empowering Flexibility</h4>

                                    </div>

                                </div>

                                <div id="tab-4" className="tab-vertical-content" >

                                    <figure><img src="images/awesome-design-img.png" alt="" /></figure>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className="generate-forms padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10">

                            <h2>Global Reach - Local Impact</h2>

                            <p className="padd-sm">ConstructCoreTech's innovative construction management software has gained traction worldwide, transforming the way projects are executed. Trusted by industry leaders across the globe, our solution ensures seamless collaboration, streamlined workflows, and unparalleled efficiency for construction professionals everywhere.</p>

                        </div>

                    </div>

                    <ul className="counter-listing">

                        <li>

                            <div className="couter-outer"><span className="counter" data-num="1">1</span><span>+</span></div>

                            <span className="sub-title">Countries</span> </li>

                        <li>

                            <div className="couter-outer"><span className="counter" data-num="70">70</span></div>

                            <span className="sub-title">Users</span> </li>

                        <li>

                            <div className="couter-outer"><span className="counter" data-num="3">3</span><span>+</span></div>

                            <span className="sub-title">PROJECTS</span> </li>

                    </ul>



                    <div className="features-carousel-sec">

                        <div className="owl-carousel owl-feature">

                            <div className="item"><img src="images/counter-sec-img.png" alt="" /></div>

                            <div className="item"><img src="images/counter-sec-img.png" alt="" /></div>

                            <div className="item"><img src="images/counter-sec-img.png" alt="" /></div>

                        </div>

                    </div>

                </div>

            </section>

            <section className="client-speak our-features padding-lg">

                <div className="container">

                    <div className="row justify-content-center head-block">

                        <div className="col-md-10"> <span>Our Features</span>

                            <h2>We are Awesome but<br />

                                Don't take our words for it</h2>

                            <p className="hidden-xs">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since beenLorem Ipsum is simply dummy</p>

                        </div>

                    </div>

                    <ul className="row features-listing ico-bg">

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><span className="icon-analytics"></span></span>

                                <h3>Data Analytics</h3>

                                <p>Unlock the potential of data-driven decision-making with our robust data analytics tools, providing valuable insights to optimize project performance.</p>

                            </div>

                        </li>

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><span className="icon-responsive"></span></span>

                                <h3>Fully Responsive</h3>

                                <p>ConstructCoreTech is designed to seamlessly adapt to any device, ensuring a smooth user experience whether on desktop, tablet, or smartphone.</p>

                            </div>

                        </li>

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><span className="icon-support"></span></span>

                                <h3>Expert Suppor</h3>

                                <p>With a dedicated support team ready to assist you, rest assured that you'll receive unparalleled customer service throughout your journey with ConstructCoreTech.</p>

                            </div>

                        </li>

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><span className="icon-settings"></span></span>

                                <h3>Advanced Solution</h3>

                                <p>Experience the power of advanced features and solutions in ConstructCoreTech, tailored to meet the specific demands of the construction industry.</p>

                            </div>

                        </li>

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><span className="icon-file"></span></span>

                                <h3>Additional Functions</h3>

                                <p>Discover a wide range of additional functions and capabilities in ConstructCoreTech, empowering you to take control of every aspect of your construction projects.</p>

                            </div>

                        </li>

                        <li className="col-md-4">

                            <div className="inner"> <span className="icon"><span className="icon-graphic"></span></span>

                                <h3>Streamlined Workflows</h3>

                                <p>Boost your team's productivity and achieve project milestones faster with ConstructCoreTech's intuitive and user-friendly interface, streamlining your project workflows.</p>

                            </div>

                        </li>

                    </ul>

                </div>

            </section>

            <section className="need-to-discuss bg-img padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10 head-block">

                            <h2>Need to Discuss with Us</h2>

                            <p>Ready to take your construction projects to the next level? Reach out to us for a personalized consultation and discover how ConstructCoreTech can transform your workflows and boost your project efficiency. Let's discuss your specific requirements and explore how our solutions can meet your unique needs.</p>

                        </div>

                    </div>

                    {/* <div className="submit-form row d-flex">

                        <div className="col-md-6">

                            <input name="First Name" placeholder="First Name" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Last Name" placeholder="Last Name" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Company" placeholder="Company" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Phone Number" placeholder="Phone Number" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Business Mail" placeholder="Business Mail" type="text" />

                        </div>

                        <div className="col-md-6">

                            <input name="Job Title" placeholder="Job Title" type="text" />

                        </div>

                        <div className="col-md-12">

                            <button className="submit-btn">Submit Now</button>

                        </div>

                    </div> */}
                    <ContactUs />

                </div>

            </section>

            <section className="still-hav-qtns-outer padding-lg">

                <div className="container">

                    <h2>Still have questions?</h2>

                    <ul className="row features-listing">

                        <li className="col-md-6 equal-hight info-content team-card">

                            {/* <div className="info-content team-card">  */}
                            <span className="icon-holder"><img src="images/stil-hav-sec-icon-1.png" alt="" /></span>

                            <h3>Help Center</h3>

                            <p>Our dedicated Help Center is here to assist you every step of the way. Whether you have questions, need technical support, or want to maximize the potential of our product, we've got you covered.</p>

                            <a href="/support" className="know-more">Know more</a>
                            {/* </div> */}

                        </li>

                        <li className="col-md-6 equal-hight info-content team-card">

                            {/* <div className="info-content team-card">  */}
                            <span className="icon-holder"><img src="images/stil-hav-sec-icon-2.png" alt="" /></span>

                            <h3>Faqs</h3>

                            <p>Our comprehensive FAQ is designed to support your journey with ease and clarity.</p>

                            <a href="/faq" className="know-more">Know more</a>
                            {/* </div> */}

                        </li>


                    </ul>

                </div>

            </section>

            {/* <section className="signup-outer gradient-bg padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10">

                            <ul className="clearfix">

                                <li> <span className="icon-men"></span>

                                    <h4>Signup for an <span>Account</span></h4>

                                </li>

                                <li> <span className="icon-chat"></span>

                                    <h4>Discuss with <span>our team</span></h4>

                                </li>

                                <li> <span className="icon-lap"></span>

                                    <h4>Receive a <span>good support</span></h4>

                                </li>

                            </ul>

                            <div className="signup-form">

                                <form action="#" method="get">

                                    <div className="email">

                                        <input name="email" type="text" placeholder="email" />

                                    </div>

                                    <div className="password">

                                        <input name="password" type="password" placeholder="password" />

                                    </div>

                                    <button className="signup-btn">Sign up Now</button>

                                </form>

                            </div>

                        </div>

                    </div>

                </div>

            </section> */}
        </div>
    )
}
