// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strike-through {
    position: relative;
    font-size: 30px;
  }
  
  .strike-through::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #C00000;
    /* transform: rotate(0deg);  */
  }`, "",{"version":3,"sources":["webpack://./src/features/pricing/StrikeThroughAmount.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,8BAA8B;EAChC","sourcesContent":[".strike-through {\r\n    position: relative;\r\n    font-size: 30px;\r\n  }\r\n  \r\n  .strike-through::after {\r\n    content: '';\r\n    position: absolute;\r\n    left: 0;\r\n    top: 50%;\r\n    width: 100%;\r\n    height: 2px;\r\n    background-color: #C00000;\r\n    /* transform: rotate(0deg);  */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
