class AppConfig {
    ENV: string;
    INVENTORY_BASEURL!: string;
    ASSET_BASEURL!: string;
    AUTH_BASEURL!: string;
    APP_ID!: string;
    //New App Configs
    BASE_URL: string | undefined = process.env.BASE_URL;
    PRODUCTS_URL: string | undefined = process.env.PRODUCTS_URL;
    USERS: string | undefined = process.env.USERS_URL || "http://44.215.80.246:7005";
    MEDIA_SERVICE = "http://44.215.80.246:7005"//process.env.MEDIA_SERVICE;
    JAVA_MEDIA_SERVICE = process.env.JAVA_MEDIA_SERVICE;
    AWSHost: string | undefined = process.env.AWS_HOST;
  
    AuthInfo!: {
        Token: string;
    };
    APPVERSION: string | undefined;
    UPSHOT_SDKID!: string;
    UPSHOT_ACCOUNTID!: string;
    PROMO_BASEURL!: string;
    AUTHDomain: any;
    SPORTSDomain: any;
    FANTASY_API: any;
    ARENA_API: any;
    // ARENA_API: any;
    CASEBreaker: any;
    CARD_HUNTER_API: any;
    GOOGLE_MAPS_API: any;
    constructor() {
        this.ENV = process.env.NODE_ENV;
        this.PRODUCTS_URL = process.env.PRODUCTS_URL;
        this.BASE_URL = process.env.BASE_URL;
        this.APPVERSION = process.env.app_version;
    }
    updateAuthInfo = (authInfo: { Token: string}) => {
        this.AuthInfo = authInfo;
    }
    LogLevel: string = 'error'; // info,debug,error
    changeLogLevel(logLevel:any) {
        if (['error', 'info', 'debug'].indexOf(logLevel) > -1) {
            this.LogLevel = logLevel;
        }
    }
 }
 const appConfig = new AppConfig();
 export default appConfig;