import React, { useEffect } from 'react'
import $ from "jquery"

export default function Loader() {
    useEffect(()=>{
        var $element = $("#loader");
        $element.fadeOut(1000);
    },[])
  return (
    <>
        <div id="loader">
            <div id="element">
                <div className="circ-one"></div>
                <div className="circ-two"></div>
            </div>
        </div>
    </>
  )
}
