import React, { Component } from "react";
import addIcon from "../../assets/images/Add.svg";
import shareIcon from "../../assets/images/Share.svg";
import printIcon from "../../assets/images/Print.svg";
import searchIcon from "../../assets/images/Search.svg";
import filter from "../../assets/images/Filter.svg";

interface bondsState {
  dbdata: Array<any>;
  checkBox: Array<any>;
  allChecked: Boolean;
  isAddButtonClicked: boolean;
  isEdit: Boolean;
  editData: any;
}

export default class Projects extends Component<{}, bondsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      dbdata: [],
      checkBox: [],
      allChecked: false,
      isAddButtonClicked: false,
      isEdit: false,
      editData: "",
    };
  }

  handleAllselect = (e: any) => {
    if (e.target.checked) {
      this.setState((prev) => ({ allChecked: true }));
      let f = this.state.dbdata.map((d: any) => ({ ...d, checked: true }));
      this.setState((prev) => ({ dbdata: f }));
    } else {
      this.setState({ allChecked: false });
      let f = this.state.dbdata.map((d: any) => ({ ...d, checked: false }));
      this.setState((prev) => ({ dbdata: f }));
    }
  };

  handleSelectEachRow = (e: any, row: any) => {
    if (e.target.checked) {
      let filtered = this.state.dbdata.map((f: any) => {
        if (f.id === row.id) {
          let d = { ...f, checked: e.target.checked };
          return d;
        } else {
          return f;
        }
      });
      this.setState({
        allChecked:
          this.state.dbdata.length ===
          this.state.dbdata.filter((r: any) => r.checked === true).length,
      });
      this.setState({ dbdata: filtered });
    } else {
      let filtered = this.state.dbdata.map((f: any) => {
        if (f.id === row.id) {
          let d = { ...f, checked: e.target.checked };
          return d;
        } else {
          return f;
        }
      });
      this.setState({ dbdata: filtered });
    }
  };

  checkBoxHandlar(e: any) {
    if (e.target.checked) {
      this.setState((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else {
      this.setState({
        checkBox: this.state.checkBox.filter((r) => r !== e.target.value),
      });
    }
  }

  closeModal() {
    this.setState((p) => ({ isAddButtonClicked: false, editData: "" }));
  }

  render() {
    const disableButton =
      this.state?.dbdata.filter((e: any) => e.checked === true).length > 0
        ? false
        : true;
    return (
      <div className="dashboard_table">
        <div className="header_section">
          <div className="title">Projects</div>
          <div className="options_section">
            <div className="input-group search_bar">
              <span className="input-group-text">
                <img src={searchIcon}></img>
              </span>
              <input
                className="form-control"
                type="text"
                placeholder="Search Projects..."
              />
            </div>
            <button
              className=""
              onClick={() =>
                this.setState((p) => ({ isAddButtonClicked: true }))
              }
            >
              <img src={addIcon}></img>
            </button>
            <div className="nav-item dropdown">
              <button
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="filter_btn"
              >
                <img
                  //   onClick={() => this.setState((p) => ({ click: !p.click }))}
                  src={filter}
                  alt="..."
                  className="dropdown-toggle"
                />
              </button>
              <div
                className="dropdown-menu dropdown-menu-end animate slideIn"
                aria-labelledby="navbarDropdown"
              >
                <a className="dropdown-item" href="#">
                  All
                </a>
                <a className="dropdown-item" href="#">
                  Admin
                </a>
                <a className="dropdown-item" href="#">
                  Teams
                </a>
              </div>
            </div>
            <button className="" disabled={disableButton}>
              <img src={shareIcon}></img>
            </button>
            <button className="" disabled={disableButton}>
              <img src={printIcon}></img>
            </button>
          </div>
        </div>
        <div className="table-responsive table_section">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div className="checkbox_div">
                    <input
                      id="checkbox"
                      className="checkbox-custom"
                      name="checkbox"
                      type="checkbox"
                      checked={
                        !this.state.dbdata?.some(
                          (rows) => rows.checked !== true
                        )
                      }
                      onChange={(e: any) => this.handleAllselect(e)}
                    />
                    <label
                      htmlFor="checkbox"
                      className="checkbox-custom-label"
                    ></label>
                  </div>
                  <label className="id">S.No</label>
                </th>
                <th>Name</th>
                <th>Account</th>
                <th>Role</th>
                <th>Team</th>
                <th>Project</th>
                <th>Status</th>
                <th>Document Management</th>
                <th>Project Management</th>
                <th>Planning</th>
                <th>Commercial</th>
              </tr>
            </thead>
            <tbody>
              {this.state?.dbdata.map((each, index) => {
                return (
                  <>
                    <div className="checkbox_div">
                      <input
                        id={`checkbox${index}`}
                        className="checkbox-custom"
                        name="checkbox"
                        type="checkbox"
                        checked={each.checked}
                        onChange={(e) => this.handleSelectEachRow(e, each)}
                      />
                      <label
                        htmlFor={`checkbox${index}`}
                        className="checkbox-custom-label"
                      ></label>
                    </div>
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "color" : "nocolor"}
                    >
                      <td>
                        <label></label>
                      </td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>{}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
