import React, { Component } from 'react'
import { api_service } from '../../api/api_service';

export default class ContactUsWithMessage extends Component {

    state={
        first_name:"",
        company:"",
        buss_email:"",
        message:"",
        phone_num:0
    }

    contactDetails() {
        let payload: any = {
            attributes: {
                type:2,
                first_name: this.state.first_name,
                company: this.state.company,
                buss_email: this.state.buss_email,
                message: this.state.message,
                phone_num: this.state.phone_num
            },
        };
        return payload;
    }

    handleSubmit(e: any) {
        console.log(this.contactDetails());
        e.preventDefault();
        try {
            api_service
                .post({
                    endPoint: api_service.api_urls.CONTACTUS,
                    domain: "http://44.215.80.246:4000",
                    payLoad: this.contactDetails(),
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.status);
                    }
                })
                .catch((err: any) => {
                    console.log(err.message);
                });
        } catch (error: any) {
            console.log(error.message);

        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    render() {
        return (
            <>
                <form name="contact-form" id="ContactForm" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row">
                        <div className="col-md-6 input-col">
                            <label>Your Name</label>
                            <input onChange={this.handleChange} name="first_name" placeholder="" type="text" required/>
                        </div>
                        <div className="col-md-6 input-col">
                            <label>Email Address</label>
                            <input name="buss_email" placeholder="" type="text" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 input-col">
                            <label>Phone</label>
                            <input onChange={this.handleChange} name="phone_num" placeholder="" type="number" required/>
                        </div>
                        <div className="col-md-6 input-col">
                            <label>Company</label>
                            <input onChange={this.handleChange} name="company" placeholder="" type="text" required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Message</label>
                            <textarea onChange={()=>this.handleChange} name="message" placeholder="" required></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn submit" type='submit'>Submit</button>
                        </div>
                        <div className="col-md-12">
                            <div className="msg"></div>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}
