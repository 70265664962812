import React, { useEffect } from 'react'
import ContactUsWithMessage from '../components/common/ContactUsWithMessage'

export default function Contact() {
    useEffect(()=>{
        var $element = $('.contact-map');

        $element

            .on('click',function() {

                $(this).find('iframe').addClass('clicked');

            })

            .on('mouseleave',function() {

                $(this).find('iframe').removeClass('clicked');

        });

    },[])
    return (
        <div>

            <section className="inner-banner contact-banner">

                <div className="container">

                    <div className="contents">

                        <h1>Unlock Your Construction Potential</h1>

                        <p>Get in touch with us to explore how ConstructCoreTech can enhance your construction projects and propel your business to new heights</p>

                    </div>

                </div>

            </section>

            <section className="contact-wrapper-outer">

                <div className="container">

                    <div className="row">

                        <div className="col-md-8 form-area">

                            <div className="contact-form-wrapper padding-lg">

                                {/* <form name="contact-form" id="ContactForm">

                                    <div className="row">

                                        <div className="col-md-6 input-col">

                                            <label>Your Name</label>

                                            <input name="your_name" placeholder="" type="text" />

                                        </div>

                                        <div className="col-md-6 input-col">

                                            <label>Email Address</label>

                                            <input name="business_email" placeholder="" type="text" />

                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col-md-6 input-col">

                                            <label>Phone</label>

                                            <input name="phone_number" placeholder="" type="text" />

                                        </div>

                                        <div className="col-md-6 input-col">

                                            <label>Company</label>

                                            <input name="company" placeholder="" type="text" />

                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col-md-12">

                                            <label>Message</label>

                                            <textarea name="message" placeholder=""></textarea>

                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col-md-6">

                                            <button className="btn submit">Submit</button>

                                        </div>

                                        <div className="col-md-12">

                                            <div className="msg"></div>

                                        </div>

                                    </div>

                                </form> */}

                                <ContactUsWithMessage/>
                            </div>

                        </div>

                        <div className="col-md-4">

                            <div className="contact-info-wrapper padding-lg">

                                <div className="contact-info">

                                    <h3>Contact Info</h3>

                                    <ul className="info-contact-box">

                                        <li>

                                            <h6>Constructcoretech</h6>

                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem</p> */}

                                        </li>

                                        <li>

                                            <h6>9989441153</h6>

                                        </li>

                                        <li> <a href="mailto:info@constructcoretech.com">info@constructcoretech.com</a> </li>

                                    </ul>

                                </div>

                                {/* <div className="social-media-box">

                                    <h6><span>Connect with</span></h6>

                                    <ul>

                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>

                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>

                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>

                                    </ul>

                                </div> */}

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className="contact-map">

                <div className="msg-box"><p><i className="fa fa-mouse-pointer" aria-hidden="true"></i> click and scroll to zoom the map</p></div>

                {/* <iframe src="https://snazzymaps.com/embed/24114" style={{ border: "none" }}></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.2846738522417!2d78.35740207591192!3d17.493919099725996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb930006df5f59%3A0x2ad2c7f2fcc1fe36!2sConstructcoretech!5e0!3m2!1sen!2sin!4v1715274185659!5m2!1sen!2sin" width="600" height="450" style={{border:"0;"}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

            </section>

            {/* <section className="signup-outer gradient-bg padding-lg">

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10">

                            <ul className="clearfix">

                                <li> <span className="icon-men"></span>

                                    <h4>Signup for an <span>Account</span></h4>

                                </li>

                                <li> <span className="icon-chat"></span>

                                    <h4>Discuss with <span>our team</span></h4>

                                </li>

                                <li> <span className="icon-lap"></span>

                                    <h4>Receive a <span>good support</span></h4>

                                </li>

                            </ul>

                            <div className="signup-form">

                                <form action="#" method="get">

                                    <div className="email">

                                        <input name="email" type="text" placeholder="email" />

                                    </div>

                                    <div className="password">

                                        <input name="password" type="password" placeholder="password" />

                                    </div>

                                    <button className="signup-btn">Sign up Now</button>

                                </form>

                            </div>

                        </div>

                    </div>

                </div>

            </section> */}
        </div>
    )
}
