import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/common/sideBar/SideBar'
import Navbarr from '../features/navBar/Navbar'

export default function ProtectedRoutes() {
    return (
        <div className="d-flex">
            <Sidebar />
            <div
                className="right_body"
                style={{
                    height: "99.6vh",
                    overflow: "auto",
                    zIndex: "0",
                    position: "fixed",
                    left: "65px",
                    width: "calc(100vw - 60px)",
                }}
            >
                <div style={{ position: "sticky", top: "0", width: "100%", zIndex: "1000" }}>
                    <Navbarr />
                </div>
                <div style={{ position: "absolute", top: "56px", width: "100%", height: "calc(100% - 56px)" }}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
