import { Component } from "react";
import { items } from "./items";
import SidebarItem from "./siderBarItemComp";
import Uma from "../../../assets/images/companyLogo.svg"
import M from "../../../assets/images/companyLogo.svg"
import Extract from "../../../assets/images/Extract.svg"
import Expand from "../../../assets/images/Expand.svg"


export default class Sidebar extends Component<{}, { sideBarClick: boolean }> {
  state = {
    sideBarClick: true,
  };
  click (){
    this.setState((p) => ({ sideBarClick: !p.sideBarClick }))
  }
  render() {
    const { sideBarClick } = this.state;
    return (
      <div
        style={{
          zIndex: "1",
          backgroundColor: "white",
          height: "100vh"
        }}
      >
        <div className={sideBarClick ? "sidebar-shrink " : "sidebar"}>
          <div className={sideBarClick ? "subsidebar_close" : "subsidebar_open"}>
            <img
              className={this.state.sideBarClick ? "logo" : "uma__logo"}
              src={
                this.state.sideBarClick ? M : Uma
              }
              onClick={() =>
                this.click()
              }
            />

            {items.map((item, index) => (
              <SidebarItem
                key={index}
                item={item}
                siderBarClick={sideBarClick}
                sidebarOpenClose={()=>this.click()}
              />
            ))}
          </div>
          <div className={this.state.sideBarClick ? "sidebar_expand" : "sidebar_extract"}>
            <div>
              {!this.state.sideBarClick && (<img src={M}></img>)}
            </div>
            <div className="extract_expand">
              <img
                className={this.state.sideBarClick ? "expand_style" : ""}
                src={this.state.sideBarClick ? Expand : Extract}
                onClick={() =>
                  // this.setState((p) => ({ sideBarClick: !p.sideBarClick }))
                  this.click()
                }
              ></img>
              {
                !this.state.sideBarClick?<span className="extract">EXTRACT</span>:<span className="expand">EXPAND</span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
